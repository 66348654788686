/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable prefer-promise-reject-errors */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios, { AxiosRequestConfig } from 'axios';
import querystring from 'query-string';

const DEBUG = false;

const instance = axios.create({
  // baseURL: 'https://raevntoysm.cfolks.pl/',
  baseURL: 'https://api.krysiak.pl/',
  timeout: 1000 * 60,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const logger = (data: Record<string, any>, url: string) => {
  if (DEBUG) {
    console.log(
      url,
      `\n\t status: ${data.status}`,
      '\n\t payload: ',
      data.data
    );
  }
  return data.data;
};

export const request = (
  _url: string,
  _config: Record<string, any> = {}
): Promise<any> => {
  if (DEBUG) {
    console.log(_url, 'config', _config);
  }

  const req: AxiosRequestConfig = {
    url: _url,
    ..._config
  };

  if (!req.headers) {
    req.headers = {};
  }
  if (_config.multipart) {
    req.headers['content-type'] = 'multipart/form-data';
  }
  if (_config.query && Object.keys(_config.query).length !== 0) {
    req.url += `?${querystring.stringify(_config.query, {
      arrayFormat: 'bracket'
    })}`;
  }

  console.log('requestTesting start req:', req);
  return instance
    .request(req)
    .then((data) => {
      console.log('requestTesting:', data);
      return logger(data, _url);
    })
    .catch((err) => {
      const { data, status } = err.response;
      console.log('requestTesting:', data, status);
      return Promise.reject({ data, status });
    });
};
