/* eslint-disable import/no-named-as-default */

/* eslint-disable import/no-named-as-default-member */

/* eslint-disable prettier/prettier */

/* eslint-disable indent */

/* eslint-disable */
import { AuthActionType, IAuthState } from '@contextTypes';

import actions from './actions';
import initialState from './state';

function authReducer(state = initialState, action: AuthActionType): IAuthState {
  switch (action.type) {
    case 'loadAuth':
      return actions.loadAuth(state, action);
    case 'logIn':
      return actions.logIn(state, action);
    case 'logOut':
      return actions.logOut(state);
    case 'setAccountDetails':
      return actions.setAccountDetails(state, action);
    case 'setBillingDetails':
      return actions.setBillingDetails(state, action);
    case 'setShippingDetails':
      return actions.setShippingDetails(state, action);
    case 'setWishlist':
      return actions.setWishlist(state, action);
    case 'setBoughtProducts':
      return actions.setBoughtProducts(state, action);
    case 'setAuthBusy':
      return actions.setAuthBusy(state, action);
    case 'setBilling':
      return actions.setBilling(state, action);
    case 'setInvoice':
      return actions.setInvoice(state, action);

    default:
      throw new Error('Wrong action type in app reducer');
  }
}
export default authReducer;
