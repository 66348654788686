import React from 'react';

import logo from './image1.png';

interface ILogoSVG {
  src?: string;
  alt?: string;
}

const LogoSVG: React.FC<ILogoSVG> = ({
  src = logo as string,
  alt = ''
}): JSX.Element => <img src={src} alt={alt} />;

export default LogoSVG;
