const OrdersSVG = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 3.25C5.32707 3.25 4.91666 3.66041 4.91666 4.16667V15.8333C4.91666 16.3396 5.32707 16.75 5.83333 16.75H14.1667C14.6729 16.75 15.0833 16.3396 15.0833 15.8333V4.16667C15.0833 3.66041 14.6729 3.25 14.1667 3.25H5.83333ZM3.41666 4.16667C3.41666 2.83198 4.49864 1.75 5.83333 1.75H14.1667C15.5014 1.75 16.5833 2.83198 16.5833 4.16667V15.8333C16.5833 17.168 15.5014 18.25 14.1667 18.25H5.83333C4.49864 18.25 3.41666 17.168 3.41666 15.8333V4.16667ZM6.75 5.83333C6.75 5.41912 7.08578 5.08333 7.5 5.08333H12.5C12.9142 5.08333 13.25 5.41912 13.25 5.83333C13.25 6.24755 12.9142 6.58333 12.5 6.58333H7.5C7.08578 6.58333 6.75 6.24755 6.75 5.83333ZM6.75 9.16667C6.75 8.75245 7.08578 8.41667 7.5 8.41667H12.5C12.9142 8.41667 13.25 8.75245 13.25 9.16667C13.25 9.58088 12.9142 9.91667 12.5 9.91667H7.5C7.08578 9.91667 6.75 9.58088 6.75 9.16667ZM6.75 12.5C6.75 12.0858 7.08578 11.75 7.5 11.75H10.8333C11.2475 11.75 11.5833 12.0858 11.5833 12.5C11.5833 12.9142 11.2475 13.25 10.8333 13.25H7.5C7.08578 13.25 6.75 12.9142 6.75 12.5Z"
        fill="#0074FF"
      />
    </svg>
  );
};

export default OrdersSVG;
