import * as React from 'react';

const DeliverySVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 5A.75.75 0 0 1 2 4.25h11a.75.75 0 0 1 .75.75v.25H18a.75.75 0 0 1 .643.364l3 5a.75.75 0 0 1 .107.386v6a.75.75 0 0 1-.75.75h-1.354a2.751 2.751 0 0 1-5.293 0H9.646a2.751 2.751 0 0 1-5.292 0H3a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 1.5 0v3.25h.604a2.751 2.751 0 0 1 5.292 0h2.604V5.75H2A.75.75 0 0 1 1.25 5Zm12.5 1.75v3.5h5.925l-2.1-3.5H13.75Zm6.5 5h-6.5v4.5h.604a2.751 2.751 0 0 1 5.293 0h.603v-4.5ZM2.25 9A.75.75 0 0 1 3 8.25h4a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 9ZM7 15.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm10 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="#21272C"
    />
  </svg>
);

export default DeliverySVG;
