/* eslint-disable import/no-named-as-default */

/* eslint-disable import/no-named-as-default-member */

/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/indent */
import { CartActionType, ICartState } from '@contextTypes';

import actions from './actions';
import initialState from './state';

function cartReducer(state = initialState, action: CartActionType): ICartState {
  switch (action.type) {
    case 'setCartBusy':
      return actions.setCartBusy(state);
    case 'initializeItems':
      return actions.initializeItems(state, action);
    case 'addToCart':
      return actions.addToCart(state, action);
    case 'removeFromCart':
      return actions.removeFromCart(state, action);
    case 'changeProductQty':
      return actions.changeProductQty(state, action);
    case 'setShippings':
      return actions.setShippings(state, action);
    case 'setShipping':
      return actions.setShipping(state, action);
    case 'setShippingLocker':
      return actions.setShippingLocker(state, action);
    case 'setShippingTotal':
      return actions.setShippingTotal(state, action);
    case 'setCoupon':
      return actions.setCoupon(state, action);
    case 'setPaymentMethodTitle':
      return actions.setPaymentMethodTitle(state, action);
    case 'loadCart':
      return actions.loadCart(state, action);
    case 'resetCart':
      return actions.resetCart(state);
    case 'setOrderFromApi':
      return actions.setOrderFromApi(state, action);
    case 'onOrderApiError':
      return actions.onOrderApiError(state, action);
    case 'changePaymentStatus':
      return actions.changePaymentStatus(state, action);
    case 'closeModal':
      return actions.closeModal(state);
    case 'toggleModal':
      return actions.toggleModal(state);
    default:
      throw new Error('Wrong action type in cart reducer');
  }
}

export default cartReducer;
