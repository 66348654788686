/* eslint-disable indent */

/* eslint-disable prettier/prettier */
import { ICustomerState, CustomerActionType } from '@contextTypes';

import actions from './actions';
import initialState from './state';

function customerReducer(
  state = initialState,
  action: CustomerActionType
): ICustomerState {
  switch (action.type) {
    case 'changeValue':
      return actions.changeValue(state, action);
    case 'loadCustomer':
      return actions.loadCustomer(state, action);
    default:
      throw new Error('Wrong action type in customer reducer');
  }
}

export default customerReducer;
