/* eslint-disable */
import {
  IFilteringState,
  AvailableSortingOptions,
  IFilterBySlug,
  IFiltersList
} from '@contextTypes';

class FilteringActions {
  loadFiltering = ({ payload }: { payload: IFilteringState }) => {
    return payload;
  };

  setSortingOptions = (
    state: IFilteringState,
    { payload }: { payload: AvailableSortingOptions }
  ) => ({
    ...state,
    sorting: payload
  });

  // addPageProductsData = (
  //   state: IFilteringState,
  //   {
  //     payload
  //   }: { payload: { pageName: string; filtersData: Record<string, string[]> } }
  // ) => ({
  //   ...state,
  //   productsPages: {
  //     ...state.productsPages,
  //     [payload.pageName]: payload.filtersData
  //   }
  // });

  addPageProductsData = (
    state: IFilteringState,
    {
      payload
    }: {
      payload: {
        pageName: string;
        filtersData: Array<string>;
        activeLabelsObj: any;
      };
    }
  ) => ({
    ...state,
    productsPages: {
      [payload.pageName]:
        state.productsPages[payload.pageName] === undefined
          ? {
              pageFilters: payload.filtersData,
              activeLabels: payload.activeLabelsObj
            }
          : {
              ...state.productsPages[payload.pageName],
              pageFilters: payload.filtersData,
              activeLabels: payload.activeLabelsObj
            }
    }
  });

  changeProductsToShow = (
    state: IFilteringState,
    { payload }: { payload: number }
  ) => ({
    ...state,
    productsFilters: {
      ...state.productsFilters,
      productsToShow: payload
    }
  });

  changeBlogSearchedPharse = (
    state: IFilteringState,
    { payload }: { payload: string }
  ) => ({
    ...state,
    blogFilters: {
      ...state.blogFilters,
      searchedPhrase: payload
    }
  });

  changeBlogPostsToShow = (
    state: IFilteringState,
    { payload }: { payload: number }
  ) => ({
    ...state,
    blogFilters: {
      ...state.blogFilters,
      postsToShow: payload
    }
  });

  // setPriceRange = (
  //   state: IFilteringState,
  //   {
  //     payload
  //   }: {
  //     payload: {
  //       maxRange: number;
  //       minRange: number;
  //     };
  //   }
  // ) => ({
  //   ...state,
  //   priceRange: payload
  // });

  setPrizeRange = (
    state: IFilteringState,
    {
      payload
    }: {
      payload: {
        range: { maxRange: number; minRange: number };
        pageName: string;
      };
    }
  ) => {
    return {
      ...state,
      productsPages: {
        ...state.productsPages,
        [payload.pageName]:
          state.productsPages[payload.pageName] === undefined
            ? {
                priceRange: payload.range
              }
            : {
                ...state.productsPages[payload.pageName],
                priceRange: payload.range
              }
      }
    };
  };

  changeFiltersDisability = (
    state: IFilteringState,
    { payload }: { payload: boolean }
  ) => ({
    ...state,
    areFiltersVisible: payload
  });

  changeProductsDisplay = (
    state: IFilteringState,
    { payload }: { payload: string }
  ) => ({
    ...state,
    productsDisplay: payload
  });

  initializeProductPage = (
    state: IFilteringState,
    { payload }: { payload: string }
  ) => ({
    ...state,
    productsPages: {
      ...state.productsPages,
      [payload]:
        state.productsPages[payload] === undefined
          ? {
              priceRange: {
                maxRange: 100000,
                minRange: 0
              },
              pageFilters: {}
            }
          : state.productsPages[payload]
    }
  });

  changeFilters = (
    state: IFilteringState,
    { payload }: { payload: IFilterBySlug }
  ) => {
    const { slug } = payload;
    const newFiltersState = state.filtersListBySlug.filter(
      (el) => el.slug !== slug
    );
    return {
      ...state,
      filtersListBySlug: [...newFiltersState, payload]
    };
  };

  changeActiveFilters = (
    state: IFilteringState,
    {
      payload
    }: {
      payload: {
        pageName: string;
        filtersList: IFiltersList[];
      };
    }
  ) => {
    return {
      ...state,
      activePagesFilters: {
        ...state.activePagesFilters,
        [payload.pageName]: payload.filtersList
      }
    };
  };
}

const actions = new FilteringActions();
export default actions;
