import * as React from 'react';

const AlertSVG = (): JSX.Element => (
  <svg
    id="alert"
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.593 2.6a2.75 2.75 0 0 1 3.8 1.03l7.096 12.244c.018.03.033.062.046.095a2.75 2.75 0 0 1-2.53 3.781H5c-.018 0-.037 0-.055-.002a.753.753 0 0 1-.094.001 2.75 2.75 0 0 1-2.406-3.781.753.753 0 0 1 .046-.094L9.588 3.63a2.75 2.75 0 0 1 1.005-1.03ZM4.945 18.251A.776.776 0 0 1 5 18.25h13.997a1.25 1.25 0 0 0 1.165-1.675l-7.07-12.199-.008-.013a1.25 1.25 0 0 0-2.188 0l-.007.013-7.07 12.2a1.25 1.25 0 0 0 1.126 1.676ZM12 8.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 6a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-1.5 0V15a.75.75 0 0 1 .75-.75Z"
      fill="#000"
    />
  </svg>
);

export default AlertSVG;
