import * as React from 'react';

const BrandFacebookSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.934 3.934A5.75 5.75 0 0 1 15 2.25h3a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-.75.75h-3a.25.25 0 0 0-.25.25v1.25H18a.75.75 0 0 1 .728.932l-1 4a.75.75 0 0 1-.728.568h-2.25V21a.75.75 0 0 1-.75.75h-4a.75.75 0 0 1-.75-.75v-6.25H7a.75.75 0 0 1-.75-.75v-4A.75.75 0 0 1 7 9.25h2.25V8a5.75 5.75 0 0 1 1.684-4.066ZM15 3.75A4.25 4.25 0 0 0 10.75 8v2a.75.75 0 0 1-.75.75H7.75v2.5H10a.75.75 0 0 1 .75.75v6.25h2.5V14a.75.75 0 0 1 .75-.75h2.414l.625-2.5H14a.75.75 0 0 1-.75-.75V8A1.75 1.75 0 0 1 15 6.25h2.25v-2.5H15Z"
      fill="#21272C"
    />
  </svg>
);

export default BrandFacebookSVG;
