const ArrowLeftSVG = (): JSX.Element => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.69702 4.46967C9.98991 4.76256 9.98991 5.23744 9.69702 5.53033L5.97735 9.25H15.8334C16.2476 9.25 16.5834 9.58579 16.5834 10C16.5834 10.4142 16.2476 10.75 15.8334 10.75H5.97735L9.69702 14.4697C9.98991 14.7626 9.98991 15.2374 9.69702 15.5303C9.40412 15.8232 8.92925 15.8232 8.63636 15.5303L3.63636 10.5303C3.4957 10.3897 3.41669 10.1989 3.41669 10C3.41669 9.80109 3.4957 9.61032 3.63636 9.46967L8.63636 4.46967C8.92925 4.17678 9.40412 4.17678 9.69702 4.46967Z"
      fill="#21272C"
    />
  </svg>
);

export default ArrowLeftSVG;
