import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  overscroll-behavior: contain;
`;

export const Dim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Container = styled.div`
  position: relative;

  width: 86vw;
  height: 90%;

  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30vh auto;
  width: 100%;
  height: 100%;
  z-index: 1002;

  @media (min-width: 1024px) {
    grid-template-columns: 454px 1fr;
    grid-template-rows: 1fr;
    max-width: 1116px;
    max-height: 727px;
  } */
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  margin: 0 auto;
  z-index: 1;

  .search-input-wrapper[_ngcontent-kil-c7] .icon[_ngcontent-kil-c7] {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 500px) {
    .list-wrapper[_ngcontent-kil-c9] {
      width: 290px !important;
    }
  }
`;

export const Close = styled.div`
  position: fixed;
  right: 25px;
  top: 15px;

  z-index: 10;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;

    path {
      fill: lightgray;
    }
  }
`;
