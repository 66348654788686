import { request } from '../../request';
import { IB2bData, IContactData, IServiceContactData } from './types';

class WPAPI {
  sendContactMessage(formData: IContactData) {
    const url = '/wp-json/contact-form-7/v1/contact-forms/317/feedback';
    const data = new FormData();
    const { name, email, subject, message } = formData;

    data.append('your-name', name);
    data.append('your-email', email);
    data.append('your-subject', subject);
    data.append('your-message', message);

    const config = {
      method: 'POST',
      multipart: true,
      data
    };

    return request(url, config);
  }

  sendServiceMessage(formData: IServiceContactData) {
    const url = `/wp-json/contact-form/v1/service`;
    const config = {
      method: 'POST',
      data: formData
    };

    return request(url, config);
  }

  sendB2bMessage(formData: IB2bData) {
    const url = `/wp-json/contact-form/v1/b2b`;
    const config = {
      method: 'POST',
      data: formData
    };

    return request(url, config);
  }

  sendNewsletter(email: string, acceptance: boolean) {
    const url = `wp-json/contact-form/v1/newsletter`;
    const config = {
      method: 'POST',
      data: {
        email,
        acceptance
      }
    };

    return request(url, config);
  }

  getStock(item_id: string | number) {
    const url = `wp-json/check/stock/${item_id}`;
    const config = { method: 'GET' };

    return request(url, config);
  }

  getStocks(items: number[]) {
    const url = `wp-json/check/cart/stocks`;
    const config = { method: 'POST', data: { items } };

    return request(url, config);
  }

  getIsOmnibus(item_id: number) {
    const url = `wp-json/omnibus/v1/product/${item_id}`;
    const config = { method: 'GET' };

    return request(url, config);
  }
}

const WPApi = new WPAPI();

export default WPApi;
