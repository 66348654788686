const TpayLogoSVG = (): JSX.Element => {
  // fill="#052554"
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.15 93.09">
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="Warstwa_1-2" data-name="Warstwa 1">
          <path
            fill="#052554"
            d="M76.54,22.78A25.81,25.81,0,0,1,84.61,16a22.06,22.06,0,0,1,10.57-2.5,19.65,19.65,0,0,1,9,1.9,17.47,17.47,0,0,1,6.22,5.24A22.24,22.24,0,0,1,114,28.45a37.77,37.77,0,0,1,1.15,9.48A43.9,43.9,0,0,1,113,51.72a37.34,37.34,0,0,1-5.94,11.39,28.68,28.68,0,0,1-9,7.74,23.84,23.84,0,0,1-11.55,2.84A23.25,23.25,0,0,1,75.78,71.4a19.39,19.39,0,0,1-7.63-7.52L61.93,93.09H49.51L66,15.26h12.1ZM71.2,50.47A13.56,13.56,0,0,0,74.47,60q3.27,3.6,9.81,3.6a13.71,13.71,0,0,0,7.57-2.13,18.29,18.29,0,0,0,5.62-5.72A28.72,28.72,0,0,0,101,47.47a38.77,38.77,0,0,0,1.2-9.65q0-6.85-3.06-10.46a9.92,9.92,0,0,0-7.95-3.6A16.28,16.28,0,0,0,84.77,25a18.8,18.8,0,0,0-5.45,3.48,20.66,20.66,0,0,0-4.14,5.24,23.23,23.23,0,0,0-2.46,6.48A61.82,61.82,0,0,0,71.2,50.47Z"
          />
          <path
            fill="#052554"
            d="M135.21,73.36q-7.52,0-12-4t-4.46-11.5a17.78,17.78,0,0,1,2.61-10,18.65,18.65,0,0,1,7-6.21,33.59,33.59,0,0,1,10-3.27,88.47,88.47,0,0,1,11.77-1.2l10.35-.44.33-1.74a22.38,22.38,0,0,0,.33-3.71,8,8,0,0,0-2.29-6.1c-1.53-1.45-4-2.18-7.52-2.18q-5.9,0-8.94,1.58t-4.69,6.16l-11.23-1.2q2.73-8.71,9.16-12.37t17-3.65q9.92,0,15.15,4.52t5.34,12.7a24.83,24.83,0,0,1-.22,3.16c-.15,1.09-.33,2.22-.55,3.38L165,71.94H153.2l1.53-7.41a38.41,38.41,0,0,1-8.29,6.38A22.81,22.81,0,0,1,135.21,73.36ZM158.65,45l-8.39.43a59.63,59.63,0,0,0-9.16,1.15,18,18,0,0,0-5.89,2.23,8.17,8.17,0,0,0-3.1,3.44,10.94,10.94,0,0,0-.93,4.63,6.73,6.73,0,0,0,2,4.85q2,2,6,2a19.92,19.92,0,0,0,6.43-1,20.15,20.15,0,0,0,5.5-2.83,16.89,16.89,0,0,0,4.15-4.36,16.5,16.5,0,0,0,2.34-5.73Z"
          />
          <path
            fill="#052554"
            d="M180.38,15.26h13.73l7.2,40.88L224,15.26h14.17L201.63,75.43q-2.61,4.36-4.85,7.69a29.05,29.05,0,0,1-4.68,5.5,17.87,17.87,0,0,1-5.4,3.32,19.08,19.08,0,0,1-7,1.15,23.69,23.69,0,0,1-5-.49,28.37,28.37,0,0,1-4.41-1.36l3.27-9.38c.8.29,1.71.56,2.73.82a13.58,13.58,0,0,0,3.27.38,7.62,7.62,0,0,0,5.28-1.8,26,26,0,0,0,4.53-6.05l3.27-5.56Z"
          />
          <path
            fill="#052554"
            d="M33.42,53.85c-.15.72-.27,1.36-.38,1.9a8.57,8.57,0,0,0-.17,1.69c0,2.11.53,3.57,1.58,4.36A6.92,6.92,0,0,0,38.76,63a22.76,22.76,0,0,0,6.76-1l1.85,9.27A36.08,36.08,0,0,1,35.92,73q-7.51,0-11.55-3.38t-4-10.24a23.73,23.73,0,0,1,.76-6l6-28.34H0l2.07-9.81h27.1L32.44,0H44.86L41.59,15.26h15.2l-2.07,9.81H39.52Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default TpayLogoSVG;
