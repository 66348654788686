import * as React from 'react';

const BrandYoutubeSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5.75A3.25 3.25 0 0 0 3.75 9v6A3.25 3.25 0 0 0 7 18.25h10A3.25 3.25 0 0 0 20.25 15V9A3.25 3.25 0 0 0 17 5.75H7ZM2.25 9A4.75 4.75 0 0 1 7 4.25h10A4.75 4.75 0 0 1 21.75 9v6A4.75 4.75 0 0 1 17 19.75H7A4.75 4.75 0 0 1 2.25 15V9Zm7.38-.653a.75.75 0 0 1 .756.01l5 3a.75.75 0 0 1 0 1.286l-5 3A.75.75 0 0 1 9.25 15V9c0-.27.145-.52.38-.653Zm1.12 1.978v3.35L13.542 12l-2.792-1.675Z"
      fill="#21272C"
    />
  </svg>
);

export default BrandYoutubeSVG;
