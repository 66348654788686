exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-auth-account-tsx": () => import("./../../../src/templates/auth/account.tsx" /* webpackChunkName: "component---src-templates-auth-account-tsx" */),
  "component---src-templates-auth-forget-password-tsx": () => import("./../../../src/templates/auth/forget-password.tsx" /* webpackChunkName: "component---src-templates-auth-forget-password-tsx" */),
  "component---src-templates-auth-login-tsx": () => import("./../../../src/templates/auth/login.tsx" /* webpackChunkName: "component---src-templates-auth-login-tsx" */),
  "component---src-templates-auth-registration-tsx": () => import("./../../../src/templates/auth/registration.tsx" /* webpackChunkName: "component---src-templates-auth-registration-tsx" */),
  "component---src-templates-auth-reset-password-tsx": () => import("./../../../src/templates/auth/reset-password.tsx" /* webpackChunkName: "component---src-templates-auth-reset-password-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-ecommerce-cart-tsx": () => import("./../../../src/templates/ecommerce/cart.tsx" /* webpackChunkName: "component---src-templates-ecommerce-cart-tsx" */),
  "component---src-templates-ecommerce-category-tsx": () => import("./../../../src/templates/ecommerce/category.tsx" /* webpackChunkName: "component---src-templates-ecommerce-category-tsx" */),
  "component---src-templates-ecommerce-checkout-tsx": () => import("./../../../src/templates/ecommerce/checkout.tsx" /* webpackChunkName: "component---src-templates-ecommerce-checkout-tsx" */),
  "component---src-templates-ecommerce-compare-tsx": () => import("./../../../src/templates/ecommerce/compare.tsx" /* webpackChunkName: "component---src-templates-ecommerce-compare-tsx" */),
  "component---src-templates-ecommerce-configurator-tsx": () => import("./../../../src/templates/ecommerce/configurator.tsx" /* webpackChunkName: "component---src-templates-ecommerce-configurator-tsx" */),
  "component---src-templates-ecommerce-product-tsx": () => import("./../../../src/templates/ecommerce/product.tsx" /* webpackChunkName: "component---src-templates-ecommerce-product-tsx" */),
  "component---src-templates-ecommerce-summary-tsx": () => import("./../../../src/templates/ecommerce/summary.tsx" /* webpackChunkName: "component---src-templates-ecommerce-summary-tsx" */),
  "component---src-templates-standard-about-tsx": () => import("./../../../src/templates/standard/about.tsx" /* webpackChunkName: "component---src-templates-standard-about-tsx" */),
  "component---src-templates-standard-b-2-b-form-tsx": () => import("./../../../src/templates/standard/b2b-form.tsx" /* webpackChunkName: "component---src-templates-standard-b-2-b-form-tsx" */),
  "component---src-templates-standard-career-tsx": () => import("./../../../src/templates/standard/career.tsx" /* webpackChunkName: "component---src-templates-standard-career-tsx" */),
  "component---src-templates-standard-catalog-tsx": () => import("./../../../src/templates/standard/catalog.tsx" /* webpackChunkName: "component---src-templates-standard-catalog-tsx" */),
  "component---src-templates-standard-catalogs-tsx": () => import("./../../../src/templates/standard/catalogs.tsx" /* webpackChunkName: "component---src-templates-standard-catalogs-tsx" */),
  "component---src-templates-standard-contact-tsx": () => import("./../../../src/templates/standard/contact.tsx" /* webpackChunkName: "component---src-templates-standard-contact-tsx" */),
  "component---src-templates-standard-cookies-tsx": () => import("./../../../src/templates/standard/cookies.tsx" /* webpackChunkName: "component---src-templates-standard-cookies-tsx" */),
  "component---src-templates-standard-credit-agricole-tsx": () => import("./../../../src/templates/standard/credit-agricole.tsx" /* webpackChunkName: "component---src-templates-standard-credit-agricole-tsx" */),
  "component---src-templates-standard-faq-tsx": () => import("./../../../src/templates/standard/faq.tsx" /* webpackChunkName: "component---src-templates-standard-faq-tsx" */),
  "component---src-templates-standard-home-tsx": () => import("./../../../src/templates/standard/home.tsx" /* webpackChunkName: "component---src-templates-standard-home-tsx" */),
  "component---src-templates-standard-privacy-policy-tsx": () => import("./../../../src/templates/standard/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-standard-privacy-policy-tsx" */),
  "component---src-templates-standard-regulations-tsx": () => import("./../../../src/templates/standard/regulations.tsx" /* webpackChunkName: "component---src-templates-standard-regulations-tsx" */),
  "component---src-templates-standard-rent-machine-tsx": () => import("./../../../src/templates/standard/rent-machine.tsx" /* webpackChunkName: "component---src-templates-standard-rent-machine-tsx" */),
  "component---src-templates-standard-service-tsx": () => import("./../../../src/templates/standard/service.tsx" /* webpackChunkName: "component---src-templates-standard-service-tsx" */),
  "component---src-templates-standard-shipping-return-tsx": () => import("./../../../src/templates/standard/shipping-return.tsx" /* webpackChunkName: "component---src-templates-standard-shipping-return-tsx" */)
}

