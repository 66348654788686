/* eslint-disable */
import { WPEcommerceApi } from '@api';
import { CartProviderProps, ItemInterface } from '@contextTypes';
import Cookies from 'js-cookie';

import React, { useReducer, useEffect, useLayoutEffect } from 'react';

import GeoWidget from '../../organisms/ecommerce/Cart/components/GeoWidget';
import CartCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const generateUuid = () =>
  Date.now().toString(36) +
  Math.random().toString(36).substring(2) +
  Math.random().toString(36).substring(2);

const CartProvider: React.FC<CartProviderProps> = ({
  children,
  onLoad = () => false,
  onStateChange = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const onCartLoad = async () => {
  //   const cartUuid = Cookies.get('cartUuid');
  //   if (!cartUuid) return;
  //   dispatch({ type: 'setCartBusy' });
  //   try {
  //     const data = await WPEcommerceApi.getCartItems(cartUuid);
  //     const cartItems = data.cart as ItemInterface[];
  //     dispatch({ type: 'initializeItems', payload: cartItems });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useLayoutEffect(() => {
  //   onLoad(dispatch);
  //   onCartLoad();
  // }, []);

  // const onCartChange = async () => {
  //   let cartUuid = Cookies.get('cartUuid');

  //   console.log('CartCtx state ', state);
  //   onStateChange(state);

  //   if (!cartUuid) {
  //     cartUuid = generateUuid();
  //     Cookies.set('cartUuid', cartUuid, { expires: 14 });
  //   }

  //   try {
  //     const data = await WPEcommerceApi.saveCartItems(cartUuid, state);
  //     console.log(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   onCartChange();
  // }, [state]);

  useLayoutEffect(() => {
    // onCartLoad();
    onLoad(dispatch);
    const localStorageCart = JSON.parse(localStorage.getItem('CartCtxItems')!);
    if (localStorageCart?.length > 0)
      dispatch({ type: 'initializeItems', payload: localStorageCart });
  }, []);

  const storageHandler = (e: any) => {
    if (e.key !== 'CartCtxItems') return;
    const newCart = JSON.parse(e.newValue);
    if (newCart?.length > 0)
      dispatch({ type: 'initializeItems', payload: newCart });
  };

  useEffect(() => {
    window.addEventListener('storage', storageHandler);

    return () => {
      window.removeEventListener('storage', storageHandler);
    };
  }, []);

  useEffect(() => {
    console.log('cartCtx provider', state);
  }, [state]);

  return (
    <CartCtx.Provider
      value={{
        state,
        dispatch
      }}
    >
      <>
        {children}
        <GeoWidget />
      </>
    </CartCtx.Provider>
  );
};

export { CartProvider, CartCtx };
