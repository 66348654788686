import { ICompareState, CompareActionType } from '@contextTypes';

import actions from './actions';
import initialState from './state';

const appReducer = (
  state = initialState,
  action: CompareActionType
): ICompareState => {
  switch (action.type) {
    case 'loadCompareState':
      return actions.loadCompareState(action.payload);
    case 'addToCompare':
      return actions.addToCompare(state, action.payload);
    case 'removeFromCompare':
      return actions.removeFromCompare(state, action.payload);
    case 'clearCompareArray':
      return actions.clearCompareArray(state);
    default:
      throw new Error(`Wrong compare type: ${String(action.type)}`);
  }
};

export default appReducer;
