/* eslint-disable react/no-unknown-property */
import * as React from 'react';

const NotFoundSVG = (): JSX.Element => (
  <svg
    version="1.1"
    id="Warstwa_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 442 283.3"
    space="preserve"
  >
    <g>
      <g>
        <path
          fill="#DDDDDD"
          d="M82.1,250.4v-50.2H16.8v-32.1l56-132.7h47.8v133.5h20.7v31.3h-20.7v50.2H82.1z M49.2,168.9h32.9V77.6
			L49.2,168.9z"
        />
        <path
          fill="#DDDDDD"
          d="M269.2,62.6c-4.1-9.5-10.3-16.9-18.7-22.2c-8.4-5.3-19.2-8-32.2-8c-13.3,0-24.1,2.7-32.6,8
			c-8.5,5.3-14.8,12.7-18.8,22.2c-4.1,9.5-6.1,20.5-6.1,33V192c0,12.2,2.1,23,6.4,32.2c4.2,9.3,10.6,16.6,19.1,21.9
			c0.3,0.2,0.6,0.4,0.9,0.5c8.3,5,18.7,7.4,31.2,7.4c12.6,0,23.1-2.7,31.6-8c4.6-2.9,8.6-6.4,12-10.4c2.8-3.4,5.2-7.2,7.1-11.5
			c4.2-9.3,6.4-20,6.4-32.2V95.6C275.3,83.1,273.2,72.1,269.2,62.6z M228.6,213.5c-2.1,3-5.6,4.5-10.4,4.5c-4.6,0-8.1-1.5-10.4-4.5
			c0-0.1-0.1-0.1-0.1-0.2c-2.2-3-3.7-6.5-4.4-10.6c-0.7-4.1-1.1-8-1.1-11.5V96.4c0-3.9,0.3-8,0.9-12.2c0.6-4.2,2-7.9,4.2-11
			c2.2-3.1,5.8-4.6,10.7-4.6c5,0,8.5,1.6,10.6,4.6c2.1,3.1,3.5,6.8,4.1,11c0.6,4.2,0.9,8.3,0.9,12.2v94.7c0,3.5-0.4,7.4-1.1,11.5
			c-0.5,2.7-1.2,5.2-2.3,7.4C230,211.3,229.3,212.4,228.6,213.5z"
        />
        <path
          fill="#DDDDDD"
          d="M362.9,250.4v-50.2h-65.3v-32.1l56-132.7h47.8v133.5H422v31.3h-20.7v50.2H362.9z M330,168.9h32.9V77.6
			L330,168.9z"
        />
      </g>
      <rect
        x="215.6"
        y="100"
        opacity="0.2"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="168.4"
        y="69.8"
        opacity="0.2"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="192.2"
        y="69.8"
        opacity="0.2"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="203.6"
        y="112.2"
        opacity="0.1"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="135.8"
        y="162.7"
        opacity="0.1"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="228.5"
        y="112.2"
        opacity="0.1"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="163.8"
        y="206.2"
        opacity="0.2"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <rect
        x="180.9"
        y="131.3"
        opacity="0.2"
        fill="#949494"
        width="21.8"
        height="8.8"
      />
      <g>
        <g>
          <path
            fill="#CDC206"
            d="M153.7,129.4l2.6,37.7l-16.3-7.6l-4.1,16.6c0,0,35.1,19.6,38.6,19c3.5-0.7-5.2-76.9-5.2-76.9L153.7,129.4z"
          />
        </g>
        <g>
          <path
            fill="#CDC206"
            d="M177.2,220.4c0,0,18.8-6.5,26.2-12.6c7.4-6.2-31.2-80.9-31.2-80.9S172.8,195.4,177.2,220.4z"
          />
        </g>
        <g>
          <path
            fill="#7E7E7D"
            d="M261.8,235.7c-3.4,4.1-7.3,7.5-12,10.4c-8.5,5.3-19,8-31.6,8c-12.5,0-22.9-2.5-31.2-7.4l3.7-38.1
				c2.3,1.6,9.8,3.7,17,4.7c3.9,0.6,7.7,0.8,10.6,0.6h0.1c3.9,0,7.1-0.9,9.4-2.7c0.9-0.3,1.8-0.7,2.7-1
				c10.4-4.2,23.2-10.7,23.3-13.2c0-0.1,0-0.2,0-0.3l2.2-0.3C256,196.2,259.3,218.5,261.8,235.7z"
          />
          <path
            fill="#949494"
            d="M218.2,254.4c-12.5,0-23-2.5-31.4-7.5c-0.1-0.1-0.2-0.2-0.2-0.3l3.7-38.1c0-0.1,0.1-0.2,0.2-0.3
				c0.1-0.1,0.2,0,0.3,0c2.3,1.6,9.7,3.6,16.9,4.7c4.3,0.6,7.9,0.8,10.6,0.6l0.1,0c3.8,0,6.9-0.9,9.2-2.6c0,0,0.1,0,0.1-0.1
				c0.9-0.3,1.8-0.7,2.7-1c12.2-4.9,23-10.9,23.1-12.9c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1l2.2-0.3
				c0.2,0,0.4,0.1,0.4,0.3c0,0.2,3.4,22.5,5.8,39.5c0,0.1,0,0.2-0.1,0.3c-3.4,4.1-7.4,7.6-12.1,10.5
				C241.5,251.7,230.8,254.4,218.2,254.4z M187.4,246.5c8.2,4.8,18.6,7.3,30.8,7.3c12.4,0,23-2.7,31.4-7.9
				c4.5-2.8,8.5-6.3,11.8-10.2c-2.2-15.8-5.3-36.1-5.7-39l-1.5,0.2c0,0,0,0,0,0c-0.2,3.1-14.5,9.9-23.5,13.6c-0.9,0.4-1.8,0.7-2.6,1
				c-2.4,1.8-5.6,2.8-9.6,2.8h-0.1c-2.7,0.3-6.4,0.1-10.7-0.6c-6.8-1-13.9-2.9-16.7-4.5L187.4,246.5z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M171.6,150.4c0,0,17.4,42.6,17.7,44.1c0.2,1.5-1.7,11.2,1.1,13.8c2.8,2.6,21.5,6.9,29.5,5.4
				c8-1.5,33.8-13.1,34-16.8c0.2-3.7-25.6-75.1-28.8-83.7c-3.2-8.6-16.4-11.6-25.2-10.8c-8.8,0.9-30.1,6.5-34,11.8
				S171.6,150.4,171.6,150.4z"
          />
          <path
            fill="#949494"
            d="M215.8,214.3c-9.1,0-23.1-3.4-25.7-5.8c-2.2-2-1.7-8-1.3-11.6c0.1-1.1,0.2-2.1,0.1-2.4
				c-0.2-1.3-12.8-32.2-17.6-44c0,0,0,0,0,0c-0.4-1.3-9.7-31-5.8-36.5c4.1-5.7,26.1-11.2,34.3-12c8.6-0.8,22.2,2,25.6,11
				c2.8,7.4,29.1,80,28.9,83.9c-0.2,4.1-26.6,15.7-34.3,17.1C218.7,214.2,217.3,214.3,215.8,214.3z M172,150.2
				c1.8,4.4,17.5,42.7,17.7,44.2c0.1,0.4,0,1.3-0.1,2.6c-0.3,3.2-0.9,9.2,1.1,11c2.8,2.6,21.4,6.8,29.2,5.3
				c8.7-1.7,33.5-13.3,33.7-16.5c0.2-3.5-25.2-73.9-28.8-83.6c-3.2-8.6-16.5-11.3-24.8-10.5c-9.1,0.9-30.1,6.6-33.8,11.7
				C162.4,119.5,171.7,149.5,172,150.2z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M173.1,110.8c0,0-2.2,19.6-0.9,23.7c1.3,4.1,7.2,15.3,7.2,15.3s9.8-12.3,11.8-13.6
					c1.9-1.3,19.8,10.1,19.8,10.1s0.6-12.3-1.9-22.4c-2.6-10.1-7.1-21.1-8.6-23c-1.5-1.9-1.5,5.2-7.1,9.9
					C187.8,115.5,173.1,110.8,173.1,110.8z"
            />
            <path
              fill="#949494"
              d="M179.4,150.1C179.4,150.1,179.4,150.1,179.4,150.1c-0.2,0-0.3-0.1-0.3-0.2c-0.2-0.5-5.9-11.3-7.2-15.4
					c-1.3-4.1,0.8-23,0.8-23.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,14.5,4.6,19.9,0.1c3.1-2.6,4.4-5.9,5.3-8
					c0.6-1.4,0.9-2.2,1.5-2.3c0.4-0.1,0.7,0.3,0.8,0.5c1.6,2.1,6.2,13.3,8.7,23.2c2.6,10.1,2,22.4,1.9,22.5c0,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.1-0.2,0.1-0.4,0c-8.7-5.5-18.3-10.8-19.4-10.1c-1.5,1-8.3,9.3-11.7,13.5C179.6,150.1,179.5,150.1,179.4,150.1z
					 M173.4,111.2c-0.3,2.9-2,19.5-0.8,23.1c1.1,3.5,5.8,12.6,6.9,14.8c1.8-2.2,9.7-12.1,11.5-13.3c1.9-1.3,15.7,7.3,19.7,9.8
					c0.1-2.6,0.3-13-2-21.7c-2.7-10.4-7.1-21.1-8.5-22.9c-0.1-0.2-0.2-0.2-0.2-0.2c-0.2,0.1-0.6,1.1-0.9,1.9
					c-0.9,2.1-2.3,5.6-5.5,8.3C188.4,115.4,175.9,112,173.4,111.2z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M171.5,114.2c0,0,2.6,6.5,8.1,14.4c5.6,7.9,13.2,13.7,13.2,13.7s6-15.6,7.7-24.9
						c1.6-9.3-1.2-18.6-1.2-18.6s-1.2,13.9-6,16.7C188.5,118.4,171.5,114.2,171.5,114.2z"
              />
              <path
                fill="#949494"
                d="M192.9,142.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-7.8-5.9-13.3-13.8c-5.5-7.8-8.1-14.4-8.2-14.5
						c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,16.9,4.1,21.6,1.4c4.7-2.7,5.9-16.3,5.9-16.5c0-0.2,0.1-0.3,0.3-0.3
						c0.2,0,0.3,0.1,0.4,0.2c0,0.1,2.8,9.5,1.2,18.8c-1.6,9.2-7.6,24.8-7.7,24.9C193.2,142.5,193.1,142.6,192.9,142.6
						C193,142.6,193,142.6,192.9,142.6z M172.1,114.7c0.8,1.8,3.3,7.3,7.8,13.7c4.8,6.8,11.2,12.1,12.8,13.3c1-2.6,6-16.1,7.5-24.4
						c1.2-6.6,0-13.4-0.7-16.5c-0.5,4-2.1,12.8-6,15.1C189.1,118.4,175.4,115.4,172.1,114.7z"
              />
            </g>
            <g>
              <path
                fill="#949494"
                d="M184.3,116.3c-6.2-0.5-12.8-2.2-12.8-2.2s2.6,6.5,8.1,14.4c0.5,0.8,1.1,1.5,1.7,2.2l9.6-11.1L184.3,116.3z
						"
              />
              <path
                fill="#949494"
                d="M181.3,131.1C181.3,131.1,181.3,131.1,181.3,131.1c-0.1,0-0.2-0.1-0.3-0.1c-0.7-0.8-1.2-1.6-1.7-2.2
						c-5.5-7.8-8.1-14.4-8.2-14.5c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,6.7,1.6,12.7,2.2c0,0,0.1,0,0.1,0l6.7,3.3
						c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3l-9.6,11.1C181.5,131.1,181.4,131.1,181.3,131.1z M172.1,114.7
						c0.8,1.8,3.3,7.3,7.8,13.7c0.4,0.6,0.9,1.2,1.4,1.9l9-10.5l-6.2-3.1C179.3,116.2,174,115.1,172.1,114.7z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#F4D904"
              d="M199.4,98.8c0,0,7.1,28.3,9.7,49.6c2.6,21.3,8.8,84.4,8.8,84.4s19.4-5.6,29.5-12.9
					c10.1-7.3,13.3-12.3,13.3-12.3s-16.6-72.5-16.6-73.6c0-1.1,1.7-11.8,1.7-11.8s29.3-13.8,40.5-22.2c11.2-8.4,8.4-12.9,3-15.1
					c-5.4-2.2-22.2,0.2-31.4,2.8c-9.3,2.6-14,4.3-14,4.3s-10.3-0.9-19.8,1.3C214.7,95.5,199.4,98.8,199.4,98.8z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M214.3,171.2c-0.3,1.1-0.7,2.2-1.1,3.5" />
            <path
              fill="#949494"
              d="M213.2,175c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.2-0.4c0.4-1.3,0.8-2.4,1.1-3.5c0.1-0.2,0.3-0.3,0.4-0.2
					c0.2,0.1,0.3,0.3,0.2,0.4c-0.3,1.1-0.7,2.2-1.1,3.5C213.5,175,213.3,175,213.2,175z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M215.2,168.7c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.2-0.4c1.5-5,2.3-8.6,3.1-14.9c1.4-10.1,2.4-25.8,2.6-27.8
					l-3.1-2c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l3.3,2.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-1.1,17.3-2.6,28
					c-0.9,6.3-1.6,10-3.2,15C215.5,168.6,215.3,168.7,215.2,168.7z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M215.8,122.5c-0.1,0-0.1,0-0.2-0.1l-2-1.3c-0.2-0.1-0.2-0.3-0.1-0.5c1.3-2.4,3.2-6.3,3.2-7.2
					c0-0.2-0.4-1.6-5.2-10.5c-0.1-0.2,0-0.4,0.1-0.5c0.2-0.1,0.4,0,0.5,0.1c5.3,9.8,5.3,10.6,5.3,10.9c0,1.2-2.4,5.9-3.1,7.2
					l1.7,1.1c0.2,0.1,0.2,0.3,0.1,0.5C216.1,122.5,216,122.5,215.8,122.5z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M209.7,99.2c0,0,0.3,0.6,0.9,1.6" />
            <path
              fill="#949494"
              d="M210.6,101.1c-0.1,0-0.2-0.1-0.3-0.2c-0.5-1-0.9-1.6-0.9-1.6c-0.1-0.2,0-0.4,0.1-0.5c0.2-0.1,0.4,0,0.5,0.1
					c0,0,0.3,0.6,0.9,1.6c0.1,0.2,0,0.4-0.1,0.5C210.7,101.1,210.7,101.1,210.6,101.1z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M228,209.7c-0.2,0-0.3-0.1-0.3-0.3l-1.5-5.2c-0.1-0.2,0-0.4,0.2-0.4c0,0,1.7-0.6,11.8-4.5
					c5.1-2,8.8-4.2,10.9-5.6c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0.2,0.1,0.4-0.1,0.5c-3.1,2.2-7,4.1-11.1,5.7c-7.9,3-10.7,4.1-11.5,4.4
					l1.3,4.5c2.4-0.5,17.9-3.9,25.3-12.3c0.1-0.1,0.3-0.2,0.5,0c0.1,0.1,0.2,0.4,0,0.5C246,206.3,228.3,209.7,228,209.7
					C228.1,209.7,228,209.7,228,209.7z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M253,191.1c0,0-0.5,0.5-1.4,1.3" />
            <path
              fill="#949494"
              d="M251.5,192.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2-0.1-0.4,0-0.5c0.9-0.8,1.4-1.3,1.4-1.3c0.1-0.1,0.4-0.1,0.5,0
					c0.1,0.1,0.1,0.4,0,0.5c0,0-0.5,0.5-1.5,1.3C251.7,192.7,251.6,192.7,251.5,192.7z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M245.9,122.5c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.3-0.2-0.4l3.9-11.4c0.1-0.2,0.3-0.3,0.4-0.2
					c0.2,0.1,0.3,0.3,0.2,0.4l-3,8.9l7.6-11.3c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.2,0.3,0.1,0.5l-9,13.6
					C246.1,122.5,246,122.5,245.9,122.5z"
            />
          </g>
          <g>
            <line fill="#FFFFFF" x1="251.5" y1="105.6" x2="250.6" y2="108.2" />
            <path
              fill="#949494"
              d="M250.6,108.6c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.2-0.4l0.9-2.6c0.1-0.2,0.3-0.3,0.4-0.2
					c0.2,0.1,0.3,0.3,0.2,0.4l-0.9,2.6C250.9,108.5,250.7,108.6,250.6,108.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#F4D904"
              d="M173.1,110.8c0,0,2.2,39.2,4.5,58.3c2.4,19.2,6.2,66.5,6.2,66.5s-7.5-0.6-12.5-6.7c-5-6-7.3-10.5-7.3-10.5
					s0-40.9,0-42.6s-3.4-21.3-6.7-31c-3.2-9.7-8-20.9-5.2-25.6c2.8-4.7,11.6-11.8,14.9-12.7C170.3,105.6,172.9,106.5,173.1,110.8z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M171.9,160c1.2,3.8,2.4,7.2,3.4,9.3" />
            <path
              fill="#949494"
              d="M175.3,169.7c-0.1,0-0.3-0.1-0.3-0.2c-0.9-2-2-5.1-3.4-9.4c-0.1-0.2,0-0.4,0.2-0.4c0.2-0.1,0.4,0,0.4,0.2
					c1.3,4.2,2.5,7.3,3.3,9.3c0.1,0.2,0,0.4-0.2,0.5C175.4,169.7,175.3,169.7,175.3,169.7z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M171.1,157.7c-0.2,0-0.3-0.1-0.3-0.2c-2.8-9.1-5.7-19.9-6.1-21.8c-0.6-2.6,2.6-7.1,3.5-8.2
					c-0.8-0.7-3.3-2.9-4.1-3.7c-0.2-0.2-0.5-0.5,0.2-5.5c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.4c-0.5,4.1-0.4,4.9-0.3,5
					c0.8,0.8,4.2,3.8,4.3,3.8c0.1,0.1,0.2,0.3,0,0.5c0,0.1-4.2,5.3-3.6,7.9c0.4,1.9,3.3,12.7,6.1,21.8c0.1,0.2,0,0.4-0.2,0.4
					C171.2,157.7,171.1,157.7,171.1,157.7z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M166.9,103.9c0,0-1.1,6.2-1.9,11.6" />
            <path
              fill="#949494"
              d="M164.9,115.8C164.9,115.8,164.9,115.8,164.9,115.8c-0.2,0-0.4-0.2-0.3-0.4c0.8-5.4,1.9-11.5,1.9-11.6
					c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1-1.1,6.2-1.9,11.6C165.3,115.7,165.1,115.8,164.9,115.8z"
            />
          </g>
          <g>
            <line fill="#FFFFFF" x1="169.4" y1="203.4" x2="165.6" y2="201.6" />
            <path
              fill="#949494"
              d="M169.4,203.7c-0.1,0-0.1,0-0.1,0l-3.8-1.8c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.2l3.8,1.8
					c0.2,0.1,0.3,0.3,0.2,0.5C169.7,203.7,169.5,203.7,169.4,203.7z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M176.1,206.9c-0.1,0-0.1,0-0.1,0l-4.8-2.3c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.2l4.3,2v-5.7
					l-10.3-4.2c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.2l10.5,4.3c0.1,0.1,0.2,0.2,0.2,0.3v6.5c0,0.1-0.1,0.2-0.2,0.3
					C176.3,206.9,176.2,206.9,176.1,206.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M157,84.9l3.7,9c0,0-4.8-5-5.9-0.6c-1.1,4.4,2.2,9.6,4.4,9.6c2.2,0,4.8,0.2,4.8,0.2s7.7,13.6,12.5,16.2
					c4.8,2.6,11.2,4.8,15.3,2.6c4-2.2,6.3-8.3,7.5-12.9c1.3-4.6,0.4-18.4,0.4-18.4l-6.6-27.2c0,0,4.6-1.7,4.6-4.8s-10.7-4-10.7-4
					s4.2-5-1.1-5.7c-5.3-0.7-22.6,9.6-23.2,9.9c-0.6,0.4,2.9-6.4-1.5-4c-4.4,2.4-9.6,14.2-9,16.9C152.8,74.4,157,84.9,157,84.9z"
            />
            <path
              fill="#949494"
              d="M187.8,123.1c-4.3,0-9.2-2.3-11.5-3.5c-4.6-2.5-11.6-14.5-12.6-16.2c-0.6,0-2.7-0.2-4.6-0.2
					c-0.9,0-1.9-0.7-2.8-1.8c-1.5-1.9-2.7-5.2-1.9-8.2c0.3-1.1,0.8-1.8,1.6-2c1.2-0.3,2.7,0.6,3.7,1.4l-3.1-7.6c0,0,0,0,0,0
					c-0.2-0.4-4.2-10.5-4.8-13.3c-0.6-2.8,4.5-14.8,9.2-17.3c1.1-0.6,1.9-0.7,2.4-0.3c0.8,0.6,0.3,2.2-0.2,3.9c0,0,0,0,0,0
					c3.3-2,17.8-10.3,22.7-9.6c1.5,0.2,2.5,0.8,2.9,1.7c0.5,1.4-0.5,3.2-1.1,4c2.5,0.3,10.4,1.3,10.4,4.3c0,2.9-3.5,4.6-4.5,5
					l6.6,26.9c0,0.6,0.9,14-0.4,18.6c-2,7.1-4.5,11.3-7.7,13.1C190.7,122.8,189.3,123.1,187.8,123.1z M156.7,91.8
					c-0.1,0-0.2,0-0.4,0c-0.5,0.1-0.9,0.7-1.1,1.5c-0.7,2.7,0.4,5.8,1.8,7.6c0.8,1,1.6,1.6,2.3,1.6c2.2,0,4.8,0.2,4.8,0.2
					c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,7.7,13.6,12.4,16.1c3,1.6,10.4,5.1,14.9,2.6c3-1.7,5.5-5.8,7.4-12.7c1.3-4.5,0.4-18.1,0.4-18.3
					l-6.6-27.2c0-0.2,0.1-0.4,0.2-0.4c0,0,4.4-1.6,4.4-4.5c0-2-6.3-3.4-10.3-3.7c-0.1,0-0.2-0.1-0.3-0.2s0-0.3,0.1-0.4
					c0.6-0.6,1.9-2.6,1.4-3.9c-0.3-0.7-1-1.1-2.3-1.3c-5-0.7-21.6,9-22.9,9.9c-0.1,0.1-0.3,0.1-0.5,0c-0.3-0.2-0.2-0.4,0.1-1.3
					c0.3-0.8,0.9-2.8,0.4-3.2c-0.1-0.1-0.4-0.2-1.6,0.4c-4.3,2.3-9.3,14.1-8.8,16.5c0.5,2.7,4.7,13.1,4.8,13.2l3.7,9
					c0.1,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4-0.1C159.8,93.4,158,91.8,156.7,91.8z"
            />
          </g>
          <g>
            <path
              fill="#7E7E7D"
              d="M187,54.5c0,0,4.2-5-1.1-5.7c-5.3-0.7-22.6,9.6-23.2,9.9c-0.6,0.4,2.9-6.4-1.5-4c-4.4,2.4-9.6,14.2-9,16.9
					c0.6,2.8,4.8,13.2,4.8,13.2l3.7,9c0,0,4.2,3.9,2.8,0.9c-1.5-2.9-4.4-9.6-3.7-10.5c0.7-0.9,4.4-2.4,4.6-4.4
					c0.2-2-3.5-7.9-2.4-9.4c1.1-1.5,16.7,1.3,19.5-0.4c2.8-1.7-0.7-5.7-0.7-5.7l12.3-1.1c0,0,4.6-1.7,4.6-4.8S187,54.5,187,54.5z"
            />
            <path
              fill="#949494"
              d="M163.6,96.3c-0.4,0-1.2-0.3-3.1-2.1c0,0-0.1-0.1-0.1-0.1l-3.7-9c-0.2-0.4-4.3-10.5-4.8-13.3
					c-0.6-2.8,4.5-14.8,9.2-17.3c1.1-0.6,1.9-0.7,2.4-0.3c0.8,0.6,0.3,2.2-0.2,3.9c0,0,0,0,0,0c3.3-2,17.8-10.3,22.7-9.6
					c1.5,0.2,2.5,0.8,2.9,1.7c0.5,1.4-0.5,3.2-1.1,4c2.5,0.3,10.4,1.3,10.4,4.3c0,3.3-4.6,5-4.8,5.1c0,0-0.1,0-0.1,0l-11.7,1
					c0.6,0.8,1.7,2.5,1.4,4c-0.1,0.7-0.5,1.3-1.2,1.7c-1.5,0.9-6.4,0.6-11.2,0.3c-3.3-0.2-7.8-0.5-8.2,0c-0.5,0.7,0.4,3,1.2,4.9
					c0.7,1.7,1.3,3.3,1.3,4.3c-0.1,1.5-1.9,2.7-3.3,3.6c-0.6,0.4-1.1,0.7-1.3,1c-0.4,0.6,1.4,5.4,3.7,10.1c0.3,0.6,0.5,1.2,0.1,1.5
					C163.9,96.2,163.8,96.3,163.6,96.3z M161,93.7c0.8,0.8,1.9,1.6,2.4,1.8c0-0.1-0.1-0.3-0.2-0.5c-0.2-0.4-4.7-9.5-3.6-10.9
					c0.3-0.3,0.8-0.7,1.5-1.1c1.2-0.8,2.9-1.9,3-3.1c0.1-0.8-0.6-2.4-1.2-4c-1-2.4-1.9-4.6-1.1-5.6c0.6-0.8,3.6-0.7,8.9-0.3
					c4.4,0.3,9.4,0.6,10.7-0.2c0.5-0.3,0.8-0.7,0.9-1.2c0.3-1.7-1.7-3.9-1.7-4c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.2-0.2,0.3-0.2
					L193,63c0.4-0.2,4.3-1.7,4.3-4.4c0-2-6.3-3.4-10.3-3.7c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.3,0.1-0.4c0.6-0.6,1.9-2.6,1.4-3.9
					c-0.3-0.7-1-1.1-2.3-1.3c-5-0.7-21.6,9-22.9,9.9c-0.1,0.1-0.3,0.1-0.5,0c-0.3-0.2-0.2-0.4,0.1-1.3c0.3-0.8,0.9-2.8,0.4-3.2
					c-0.1-0.1-0.4-0.2-1.6,0.4c-4.3,2.3-9.3,14.1-8.8,16.5c0.5,2.7,4.7,13.1,4.8,13.2L161,93.7z M193.1,63.3L193.1,63.3L193.1,63.3z
					"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M189.4,99.9c0,0-1.7,3.1-4.2,3.3" />
            <path
              fill="#949494"
              d="M185.2,103.6c-0.2,0-0.3-0.1-0.4-0.3c0-0.2,0.1-0.4,0.3-0.4c2.3-0.2,3.9-3.1,3.9-3.1
					c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.2,0.3,0.1,0.5C189.7,100.2,188,103.4,185.2,103.6C185.2,103.6,185.2,103.6,185.2,103.6z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M178,91.5c0.5,1.1,0.3,2.3-0.5,2.7c-0.8,0.4-1.8-0.2-2.3-1.3c-0.5-1.1-0.3-2.3,0.5-2.7
					C176.5,89.8,177.5,90.4,178,91.5z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M192.7,88.6c0.2,1.2-0.3,2.3-1.2,2.4s-1.7-0.7-1.9-1.9c-0.2-1.2,0.3-2.3,1.2-2.4
					C191.6,86.5,192.5,87.4,192.7,88.6z"
            />
          </g>
          <g>
            <path fill="#FFFFFF" d="M193.3,108.6c0,0-5.5-1.4-8.1,2.5" />
            <path
              fill="#949494"
              d="M185.2,111.5c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.1-0.5c2.7-4.1,8.4-2.7,8.5-2.7
					c0.2,0,0.3,0.2,0.3,0.4s-0.2,0.3-0.4,0.3c-0.1,0-5.3-1.3-7.7,2.4C185.4,111.4,185.3,111.5,185.2,111.5z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M168.8,89.8c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.7-0.2-1c0.1-0.2,3-4.1,10.4-1.7c0.4,0.1,0.6,0.5,0.5,0.9
					c-0.1,0.4-0.5,0.6-0.9,0.5c-6.4-2-8.7,1.1-8.8,1.2C169.3,89.7,169,89.8,168.8,89.8z"
            />
          </g>
          <g>
            <path
              fill="#949494"
              d="M187.4,86.3c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.6-0.5-0.5-0.9c0.1-0.2,1.5-4.7,6.9-5.1c0.4,0,0.7,0.3,0.8,0.7
					c0,0.4-0.3,0.7-0.7,0.8c-4.4,0.3-5.6,4-5.6,4.1C188,86.1,187.7,86.3,187.4,86.3z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M197.7,81.9c0,0,1.3-2.9,2.8-1.1c1.5,1.8,2,5.5,1.1,7.9c-0.9,2.4-1.5,4.4-1.5,4.4l-0.4-2.6L197.7,81.9z"
            />
            <path
              fill="#949494"
              d="M200.1,93.5C200.1,93.5,200.1,93.5,200.1,93.5c-0.2,0-0.3-0.1-0.3-0.3l-0.4-2.6l-2-8.6c0-0.1,0-0.2,0-0.2
					c0.1-0.2,0.8-1.8,1.9-1.9c0.5-0.1,1,0.2,1.4,0.7c1.6,2,2.1,5.8,1.2,8.3c-0.9,2.3-1.5,4.4-1.5,4.4
					C200.4,93.4,200.3,93.5,200.1,93.5z M198.1,82l2,8.5l0.1,1c0.3-0.8,0.6-1.8,1-2.9c0.9-2.2,0.4-5.8-1.1-7.6
					c-0.3-0.3-0.5-0.5-0.8-0.5C198.8,80.6,198.3,81.5,198.1,82z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M231.6,67.6c0,0-20.2-3.7-21.5-3.7c-1.3,0-16.4,2.8-18.6,3.7c-2.2,0.9-18.9,5.7-18.6,6.8
					c0.4,1.1,2.4,1.5,2.4,1.5s0.4,1.7,6.3,2.4c5.9,0.7,19.7-1.1,19.9,0.2c0.2,1.3-0.9,2.4-0.9,2.4l1.7,2.9c0,0,6.1-0.9,8.1-2.4
					c2-1.5,7.7-6.1,7.7-6.1l12.3,0.9L231.6,67.6z"
            />
            <path
              fill="#949494"
              d="M202.1,84.1c-0.1,0-0.2-0.1-0.3-0.2l-1.7-2.9c-0.1-0.1-0.1-0.3,0.1-0.4c0,0,1-1,0.8-2.1
					c-0.4-0.3-4.7-0.1-8.2,0c-4,0.2-8.6,0.4-11.4,0.1c-4.9-0.6-6.1-1.9-6.5-2.4c-0.6-0.1-2.1-0.6-2.5-1.7c0-0.1,0-0.2,0-0.4
					c0.4-0.9,4.8-2.4,13.8-5.2c2.3-0.7,4.3-1.4,5-1.6c2.2-0.9,17.4-3.7,18.7-3.7c1.3,0,20.8,3.5,21.6,3.7c0.2,0,0.3,0.2,0.3,0.4
					l-1.3,8.6c0,0.2-0.2,0.3-0.4,0.3l-12.2-0.9c-0.8,0.6-5.8,4.6-7.6,6C208.4,83.2,202.4,84.1,202.1,84.1
					C202.2,84.1,202.1,84.1,202.1,84.1z M200.9,80.9l1.4,2.5c1.1-0.2,6-1,7.7-2.3c2-1.4,7.7-6,7.7-6.1c0.1-0.1,0.2-0.1,0.2-0.1
					l12,0.9l1.2-8c-7.1-1.3-20.1-3.6-21.1-3.6c-1.3,0-16.3,2.8-18.4,3.7c-0.7,0.3-2.6,0.9-5,1.7c-4.4,1.4-12.4,3.9-13.3,4.8
					c0.3,0.7,1.6,1,2.1,1.1c0.1,0,0.2,0.1,0.3,0.3l0,0c0,0,0.5,1.4,6,2.1c2.7,0.3,7.3,0.1,11.3-0.1c6.6-0.3,8.8-0.4,8.9,0.6
					C201.9,79.5,201.2,80.5,200.9,80.9z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M186.7,70.1c0,0-8.3,3.5-9.6,4.2c-1.3,0.7,2.4,2.2,4.6,1.8c2.2-0.4,10.7-3.7,12-4c1.3-0.4,7.2,1.1,11.4,1.7
					c4.2,0.6,7.2-0.7,7.9-1.1"
            />
            <path
              fill="#949494"
              d="M181,76.6c-1.8,0-4.1-0.8-4.4-1.7c0-0.1-0.2-0.6,0.4-0.9c1.3-0.7,9.3-4.1,9.6-4.3c0.2-0.1,0.4,0,0.5,0.2
					s0,0.4-0.2,0.5c-0.1,0-8.3,3.5-9.5,4.2c-0.1,0-0.1,0.1-0.1,0.1c0.1,0.4,2.6,1.4,4.5,1.1c1.5-0.2,5.9-1.9,8.8-2.9
					c1.5-0.5,2.7-1,3.1-1.1c0.8-0.2,2.9,0.1,6.3,0.8c1.8,0.3,3.6,0.7,5.2,0.9c4.1,0.5,6.9-0.7,7.7-1.1c0.2-0.1,0.4,0,0.5,0.2
					c0.1,0.2,0,0.4-0.2,0.5c-0.8,0.4-3.8,1.7-8.1,1.1c-1.6-0.2-3.5-0.6-5.3-0.9c-2.6-0.5-5.3-1-6-0.8c-0.4,0.1-1.6,0.5-3.1,1.1
					c-3.1,1.1-7.4,2.7-8.9,3C181.5,76.6,181.2,76.6,181,76.6z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#F4D904"
            d="M281.4,97c0,0-6.5,1.7-14.2,0c-7.7-1.7-28.2-11.8-28.2-11.8l-14-4.1l3.2-16.4c0,0,35.3,4.3,45.2,8
				c9.9,3.7,24.3,12.1,23.9,15.1c-0.4,3-8.4,9.5-8.4,9.5"
          />
        </g>
        <g>
          <path
            fill="#949494"
            d="M179.2,178.6c-9.6,0-18.8-10.5-19.3-11c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0
				c0.1,0.1,12.9,14.6,23.5,9.8c10.6-4.9,16.1-36.4,16.1-36.7c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.4
				c-0.2,1.3-5.6,32.2-16.5,37.2C182.5,178.2,180.9,178.6,179.2,178.6z"
          />
        </g>
        <g>
          <path
            fill="#949494"
            d="M165.2,168.2c-12.7,0-22.3-11.7-22.4-11.8c-0.1-0.2-0.1-0.4,0.1-0.5c0.2-0.1,0.4-0.1,0.5,0.1
				c0.1,0.1,10.3,12.7,23.6,11.5c13.6-1.2,17.9-17.3,17.8-24.5c0-0.2,0.2-0.4,0.3-0.4c0.2,0,0.4,0.2,0.4,0.3
				c0.1,7.4-4.3,23.9-18.4,25.2C166.4,168.1,165.8,168.2,165.2,168.2z"
          />
        </g>
        <g>
          <g>
            <g>
              <rect
                x="141.2"
                y="146.8"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 57.6358 -37.5009)"
                fill="#C5C6C6"
                width="4.6"
                height="21.4"
              />
            </g>
            <g>
              <rect
                x="143.6"
                y="145"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 54.3582 -39.2333)"
                fill="#DDDDDD"
                width="7.1"
                height="3.1"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="150.8"
                y="150"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 59.1546 -40.3747)"
                fill="#C5C6C6"
                width="4.6"
                height="21.4"
              />
            </g>
            <g>
              <rect
                x="153.2"
                y="148.3"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 55.8763 -42.1068)"
                fill="#DDDDDD"
                width="7.1"
                height="3.1"
              />
            </g>
          </g>
          <g>
            <rect
              x="140"
              y="153.3"
              transform="matrix(0.9479 0.3185 -0.3185 0.9479 59.1011 -38.5354)"
              fill="#C5C6C6"
              width="14.8"
              height="16.4"
            />
          </g>
          <g>
            <g>
              <polygon
                fill="#D3D4D4"
                points="139,177.2 125.9,172.9 132.8,161.4 140.4,163.9 					"
              />
            </g>
            <g>
              <rect
                x="123.2"
                y="174.7"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 64.7333 -31.9605)"
                fill="#C5C6C6"
                width="13.8"
                height="14.6"
              />
            </g>
            <g>
              <rect
                x="133"
                y="148.6"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 56.8754 -36.1133)"
                fill="#DDDDDD"
                width="11.9"
                height="14.6"
              />
            </g>
          </g>
          <g>
            <image
              width="61"
              height="67"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAABBCAYAAACAXrcqAAAACXBIWXMAAC4jAAAuIwF4pT92AAAH
mklEQVRogWJkoDG4dv3af3Ks0NLUop7bGBgYAAAAAP//oqlHyfUkOqDY0wwMDAAAAAD//xoSHkUG
ZHmagYEBAAAA//+imUdp4Ul0QLSnGRgYAAAAAP//oolHqeZJZNcRMBGvpxkYGAAAAAD//xqcHsXn
KiJMxvA0AwMDAAAA//+iukfJ9iSaSxj/IwT+M+IwklhPMzAwAAAAAP//oqpHSfYkFtvhHgRRWEzD
6mlCtjIwMAAAAAD//2IhyWHUAPg8BxdAotE8AVOL4mFs0YWsj4GBAQAAAP//olqMEoxNPEkTXQ0j
IyPD//+kxxzM8yCz4QEBohgYGAAAAAD//6J9jJLgQTCTEcKB0SgeJhBzyMke7lmQ0H8GBgAAAAD/
/6JKjGKNTUIeROdCPYYLgDyM1fM4NUApkGf/MzAAAAAA//+i2KMYnkSOHQo9Rw6ABQg4MEAI5NH/
DAwAAAAA//+iWdJF8SSRHmRiYgLT//79I99eZLNhSZjhPwMAAAD//6IoSHHFJi5P4otBmCeJAfgC
AmQOSB6WvEGeZPjPwAAAAAD//6JJjIKSC9ZCB4fDYACcysCxgG4ebj24AErJzcDAAAAAAP//IjtG
icqbBGIT3ZPUAiDrYbEOorU0tRgBAAAA//8iK0ZJKoBwAKyeJEYroQDBZgYDAwMAAAD//6JdPYpU
+eNVRsCTjEyMDP//EahL0dQz/IXmT5g+BgYGAAAAAP//Itmj1IxNfJ4EOxgLm1gAsuPvv78Q5QwM
DAAAAAD//6JajBKTLwklV3I8hN9R0KTOwMAAAAAA//8iyaP4YhPDDjxJFqU9Tm3PgQ1FK9wYGBgA
AAAA//8i2qOk1Jn4SliSCh5yAXqBxcDAAAAAAP//Ir6WRgbYHDlYPAkFf/8jGg0MDAwMAAAAAP//
IsqjKLFJoL5EB/gqd2onW0YGqF3oxjIwMAAAAAD//yK7MCKl8KFHwQP3JKogJBkzMDAAAAAA//8i
6FFssYnuSaKSK7L9VPIkuuf+M6C2gUHu1NTUZGRgYGAAAAAA///C61GqehKmnkJPYo05LACcP2FW
MTAwAAAAAP//onrLiBbJlSjPISVTSOsIqYPOwMAAAAAA///CaQK5sYnTHWR4EuRBoj2JBP7/RYxG
gAEDAwMAAAD//6JqjKLEJgUxSWzyxFXag+IDpX3MwMAAAAAA///C6lFyYhMjySJ5FmQpIc9S6jm8
vRoGBgYAAAAA///CMB1XnUkIwPp/KO16EvqYoBITvdREcQcMY/MEmhxGSc/AwAAAAAD//yIqGInt
lVADYHiWgNXICQWUKkCpB9QqQglkBgYGAAAAAP//QvEoRnsWecgQDWAbcsQaqzCj0PIMPoDiWZA2
dEczIjCqc/+BswhGa4yBgQEAAAD//8Ifo9SISBrMkuIKM1g+Bw+OMfxHzKoxMDAAAAAA//+Ce5To
CSIKHE52rGIBJBjFwMDAwAAAAAD//wJ7lNBIO648SnTyRVJGURImRT0yYGBgAAAAAP//Ir2bRk5y
Rs9LpEYHXCOqh2HGgGkY+99/zAhgYGAAAAAA//9iIjc2wXKE5ktg0lgKFGI9S1QSBo9RI9SBB62R
AQMDAwAAAP//woxRIudO8HmSkikFbADDs3jCCKvdDAwMAAAAAP//Im+EgQDA2dkmM1YhWnF7llCs
MzAwMAAAAAD//0J1Eb6USEYrCewIULsTitEdSCrA6ln0wMM2pcjAwAAAAAD//8IZoxjJlkgDYQDk
WWQM8zS6WaQWTMTEHgZgYGAAAAAA///C6VFQawijRYTFs0RNyqJVO2APw2IDVJBQ4FkYG2QGqMQF
AYxIYmBgAAAAAP//Qu29oHWvIA5DmxnD0qFGmbUiopAC5WF4ZwjmWdA0Amg8lsguHbaYBZsPLoFR
W0UMDAwMAAAAAP//wuymYRn+QI5ZuKfRIwEmjBbD2DwOchCswEKZJkSLXaoNoDEwMAAAAAD//0Ix
CW8zENv8CIGFFyjCBGa5IeZhmkGsZ0HJFlwmQHsuKDHKwMAAAAAA///CaQpVPI3uaBp5FpQKQNrA
jXkwGy3pMjAwAAAAAP//Iiq4yPE0Rt4m4GF8I4dgJh7PgpP7f+joPDaPMjAwAAAAAP//IjkTkOpp
sDCR8zMggHMWHM3TyHkZxsKZdBkYGAAAAAD//6Iot5OyWozUBRzEeBiyvAbChC3QgBWGKB5lYGAA
AAAA//+iWrGG09Nog2vkJmmIGahyyGsVwJ6ErkABARSPMjAwAAAAAP//oslgEMk9IiI7C+htaJSm
Jj6PMjAwAAAAAP//ovmoF608jQ5ghRAMoHiUgYEBAAAA//+i3/AeDTyN3DjBF5sMDAwMAAAAAP//
oqtHkQGlnoZ7Ek7hjk0GBgYGAAAAAP//GjCPIgNyRjnQCzW8HmVgYAAAAAD//xoUHkUGxHia0HJz
DI8yMDAAAAAA//8adB5FBjhXwaA3E9H8jeFRBgYGAAAAAP//GtQeRQakbEzA8CgDAwMAAAD//xoy
HkUGhDyN4VEGBgYAAAAA//8akh6FAVwexvAoAwMDAAAA//8a0h5FBsiexvAoAwMDAAAA//8aGZiB
gQEAAAD//wMAhM652QgVZeAAAAAASUVORK5CYII="
              transform="matrix(0.24 0 0 0.24 144.8667 167.7333)"
            />
            <g>
              <rect
                x="142.3"
                y="181.1"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 67.7718 -37.7084)"
                fill="#C5C6C6"
                width="13.8"
                height="14.6"
              />
            </g>
            <g>
              <rect
                x="152.1"
                y="155"
                transform="matrix(0.9479 0.3185 -0.3185 0.9479 59.9122 -41.8605)"
                fill="#DDDDDD"
                width="11.9"
                height="14.6"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M131.2,146.1c0,0-1.5,2.5-1.1,3.2c0.4,0.7,4.8,3.1,5.5,3.3c0.6,0.2,2.3-0.7,2.2-1.9
					c-0.1-1.2-3.5-3.7-4.6-4.3C132.1,145.9,131.6,145.8,131.2,146.1z"
            />
            <path
              fill="#949494"
              d="M135.7,153c-0.1,0-0.2,0-0.3,0c-0.7-0.2-5.2-2.6-5.7-3.5c-0.5-0.8,0.7-3,1.1-3.6c0,0,0-0.1,0.1-0.1
					c0.7-0.5,1.4-0.3,2.5,0.3c0.5,0.3,4.6,3.1,4.7,4.6c0,0.6-0.2,1.2-0.8,1.6C136.9,152.7,136.2,153,135.7,153z M131.4,146.4
					c-0.7,1.2-1.2,2.5-1,2.8c0.4,0.6,4.6,3,5.3,3.2c0.2,0.1,0.8-0.1,1.2-0.5c0.2-0.2,0.6-0.6,0.6-1c-0.1-0.9-3-3.2-4.4-4
					C132,146.2,131.6,146.2,131.4,146.4z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M128.7,151.1c0,0-0.7,2.8-0.1,3.4c0.6,0.6,5.5,1.6,6.2,1.6c0.7,0,2-1.4,1.6-2.5c-0.4-1.1-4.4-2.5-5.6-2.8
					C129.6,150.5,129.1,150.6,128.7,151.1z"
            />
            <path
              fill="#949494"
              d="M134.8,156.4c-0.7,0-5.7-1-6.4-1.7c-0.7-0.7-0.2-3,0-3.8c0,0,0-0.1,0.1-0.1c0.5-0.7,1.3-0.7,2.5-0.4
					c0.6,0.2,5.3,1.6,5.9,3c0.2,0.5,0.1,1.2-0.3,1.8C136.1,155.9,135.4,156.4,134.8,156.4C134.8,156.4,134.8,156.4,134.8,156.4z
					 M129.1,151.2c-0.3,1.4-0.4,2.8-0.2,3c0.5,0.5,5.3,1.5,5.9,1.5c0,0,0,0,0,0c0.2,0,0.7-0.3,1-0.8c0.2-0.2,0.4-0.7,0.2-1.1
					c-0.3-0.8-3.8-2.2-5.4-2.6C129.5,150.9,129.2,151,129.1,151.2z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M127.4,156c0,0-0.7,2.8-0.1,3.4c0.6,0.6,5.5,1.6,6.2,1.6c0.7,0,2-1.4,1.6-2.5c-0.4-1.1-4.4-2.5-5.6-2.8
					C128.3,155.5,127.7,155.5,127.4,156z"
            />
            <path
              fill="#949494"
              d="M133.5,161.4c-0.7,0-5.7-1-6.4-1.7c-0.7-0.6-0.2-3,0-3.8c0,0,0-0.1,0.1-0.1c0.5-0.7,1.3-0.7,2.5-0.4
					c0.6,0.2,5.3,1.6,5.9,3c0.2,0.5,0.1,1.2-0.3,1.8C134.7,160.8,134.1,161.4,133.5,161.4C133.5,161.4,133.5,161.4,133.5,161.4z
					 M127.7,156.2c-0.3,1.4-0.4,2.8-0.2,3c0.5,0.5,5.3,1.5,5.9,1.5c0,0,0,0,0,0c0.2,0,0.7-0.3,1-0.8c0.2-0.2,0.4-0.7,0.2-1.1
					c-0.3-0.8-3.8-2.2-5.4-2.6C128.2,155.8,127.9,156,127.7,156.2z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M128,160.8c0,0-0.7,2.8-0.1,3.4c0.6,0.6,5.5,1.6,6.2,1.6c0.7,0,2-1.4,1.6-2.5c-0.4-1.1-4.4-2.5-5.6-2.8
					C128.9,160.2,128.3,160.3,128,160.8z"
            />
            <path
              fill="#949494"
              d="M134.1,166.1c-0.7,0-5.7-1-6.4-1.7c-0.7-0.7-0.2-3,0-3.8c0,0,0-0.1,0.1-0.1c0.5-0.7,1.3-0.7,2.5-0.4
					c0.6,0.2,5.3,1.6,5.9,3c0.2,0.5,0.1,1.2-0.3,1.8C135.4,165.5,134.7,166.1,134.1,166.1C134.1,166.1,134.1,166.1,134.1,166.1z
					 M128.3,160.9c-0.3,1.4-0.4,2.8-0.2,3c0.5,0.5,5.3,1.5,5.9,1.5c0,0,0,0,0,0c0.2,0,0.7-0.3,1-0.8c0.2-0.2,0.4-0.7,0.2-1.1
					c-0.3-0.8-3.8-2.2-5.4-2.6C128.8,160.6,128.5,160.7,128.3,160.9z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NotFoundSVG;
