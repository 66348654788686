/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { WPAuthApi, WPEcommerceApi } from '@api';
import { AuthProviderProps } from '@contextTypes';
import Cookies from 'js-cookie';

import { useReducer, useEffect, useState, useLayoutEffect } from 'react';

import AuthCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  onLoad = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [firstRender, setFirstRender] = useState(true);

  useLayoutEffect(() => {
    onLoad(dispatch);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      setFirstRender(false);
      onLoad(dispatch);
      return;
    }

    dispatch({
      type: 'setAuthBusy',
      payload: {
        isBusy: true
      }
    });

    WPAuthApi.validateToken(token)
      .then(() => {
        const auth = localStorage.getItem('AuthCtx');
        if (auth) {
          const parsedAuth = JSON.parse(auth);
          dispatch({
            type: 'loadAuth',
            payload: {
              auth: parsedAuth
            }
          });
          dispatch({
            type: 'setAuthBusy',
            payload: { isBusy: false }
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'logOut'
        });
        dispatch({
          type: 'setAuthBusy',
          payload: {
            isBusy: false
          }
        });
      })
      .finally(() => {
        setFirstRender(false);
        onLoad(dispatch);
        dispatch({
          type: 'setAuthBusy',
          payload: {
            isBusy: false
          }
        });
      });
  }, []);

  useEffect(() => {
    if (!firstRender) {
      console.log('AuthCtx state', state);
      localStorage.setItem('AuthCtx', JSON.stringify(state));
    }
  }, [state, firstRender]);

  useEffect(() => {
    if (state.loggedIn) {
      WPEcommerceApi.getUserOrders(state.id)
        .then((res) => {
          const boughtProducts = res
            .filter((el: { status: string }) => el.status === 'completed')
            .reduce(
              (prev: number[], curr: { items: { database_id: number }[] }) => {
                if (prev.length > 0) {
                  return [
                    ...prev,
                    curr.items.map(
                      (item: { database_id: number }) => item.database_id
                    )
                  ];
                }
                return [
                  curr.items.map(
                    (item: { database_id: number }) => item.database_id
                  )
                ];
              },
              []
            )
            .reduce(
              (total: number[], amount: number[]) => total.concat(amount),
              []
            )
            .filter(
              (value: number, index: number, self: number[]) =>
                self.indexOf(value) === index
            );
          console.log('CtxLogin', boughtProducts);
          dispatch({
            type: 'setBoughtProducts',
            payload: boughtProducts
          });
        })
        .catch(() => {
          dispatch({
            type: 'setBoughtProducts',
            payload: []
          });
        });

      WPAuthApi.getWishlist(state.id)
        .then((res) => {
          dispatch({
            type: 'setWishlist',
            payload: res.items
          });
        })
        .catch(() => {
          dispatch({
            type: 'setWishlist',
            payload: []
          });
        });
    }
  }, [state.id, state.loggedIn]);

  return (
    <AuthCtx.Provider
      value={{
        state: { ...state },
        dispatch
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export { AuthProvider, AuthCtx };
