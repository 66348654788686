/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable indent */

/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/indent */
import type { IFilteringState, FilteringActionType } from '@contextTypes';

import actions from './actions';
import initialState from './state';

const appReducer = (
  state = initialState,
  action: FilteringActionType
): IFilteringState => {
  switch (action.type) {
    case 'loadFiltering':
      return actions.loadFiltering(action);
    case 'setSortingOptions':
      return actions.setSortingOptions(state, action);
    case 'setPriceRange':
      return actions.setPrizeRange(state, action);
    case 'changeBlogSearchedPharse':
      return actions.changeBlogSearchedPharse(state, action);
    case 'changeBlogPostsToShow':
      return actions.changeBlogPostsToShow(state, action);
    case 'addPageProductsData':
      return actions.addPageProductsData(state, action);
    case 'changeProductsToShow':
      return actions.changeProductsToShow(state, action);
    case 'changeFiltersDisability':
      return actions.changeFiltersDisability(state, action);
    case 'changeProductsDisplay':
      return actions.changeProductsDisplay(state, action);
    case 'changeFilters':
      return actions.changeFilters(state, action);
    case 'changeActiveFilters':
      return actions.changeActiveFilters(state, action);
    default:
      throw new Error('Wrong sorting options');
  }
};

export default appReducer;
