/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAppState, IAppActions } from '@contextTypes';

import React from 'react';

import {
  CustomerProvider,
  AppProvider,
  WindowProvider,
  useActions,
  CartProvider,
  AppCtx,
  AuthProvider,
  useContextState,
  FilteringProvider,
  CompareProvider
} from '@components/contexted';

import Loader, { WholePageLoader } from './components/layout/PageLoader';
import './styles/loader.css';

interface ProviderProps {
  children: React.ReactNode;
}

interface ContextsWrapperProps {
  element: React.ReactNode;
}

const OuterProviders: React.FC<ProviderProps> = ({ children }) => (
  <WindowProvider>
    <AppProvider>{children}</AppProvider>
  </WindowProvider>
);

const InnerProviders: React.FC<ProviderProps> = ({ children }) => {
  const { loaded } = useActions<IAppActions>(AppCtx, 'loaded');
  const { imBusy } = useContextState<IAppState>(AppCtx, 'imBusy');

  const onLoadSearchData = () => {
    loaded();
  };

  return (
    <CustomerProvider onLoad={onLoadSearchData}>
      <AuthProvider>
        <CartProvider>
          <CompareProvider>
            <FilteringProvider>
              {imBusy ? <WholePageLoader /> : null}
              {children}
            </FilteringProvider>
          </CompareProvider>
        </CartProvider>
      </AuthProvider>
    </CustomerProvider>
  );
};

const ContextsWrapper: React.FC<ContextsWrapperProps> = ({
  element
}): JSX.Element => (
  <OuterProviders>
    <InnerProviders>{element}</InnerProviders>
  </OuterProviders>
);

export default ContextsWrapper;
