/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { WPApi } from '@api';
import { AppProviderProps } from '@contextTypes';

import React, { useReducer, useEffect, useLayoutEffect, useMemo } from 'react';

import localFile from '../../../../local-cache.json';
import AppCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const AppProvider: React.FC<AppProviderProps> = ({
  children,
  onLoad = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const productsList = useMemo(() => localFile, [localFile]);

  const itemsDatabase = productsList.map((el) => el.databaseId);
  const handleCheckCartItems = async () => {
    const { response } = await WPApi.getStocks(itemsDatabase);
    console.log('productsStockQuantity', response.data);

    dispatch({
      type: 'changeProductsStock',
      payload: response.data || []
    });
  };

  useLayoutEffect(() => {
    onLoad(dispatch);
    handleCheckCartItems();
  }, []);

  useEffect(() => {
    console.log('AppCtx state ', state);
    localStorage.setItem('AppCtx', JSON.stringify(state));
    // onStateChange(state)
  }, [state]);

  return (
    <AppCtx.Provider
      value={{
        state: { ...state },
        dispatch
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export { AppProvider, AppCtx };
