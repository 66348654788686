import { ICartState } from '@contextTypes';

const initialState: ICartState = {
  isBusy: false,
  items: [],
  error: false,
  coupon: '',
  unit: 'PLN',
  shipping: {
    method_id: '',
    method_title: '',
    total: 0,
    locker: null
  },
  paymentMethodTitle: '',
  shippingRules: {},
  shippings: [],
  orderInApi: {},
  sum: {
    totalValue: 0,
    totalQuantity: 0
  },

  payment: {
    msg: 'Proszę czekać...',
    phase: 'idle'
  },
  isModalOpen: false
};

export default initialState;
