import { getCartItems, getModifiedSingleItem } from './utils';

declare global {
  interface Window {
    fbq: any;
  }
}

class PixelController {
  pageView() {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        console.log('Analytics - FBQ - pageView');

        window.fbq('track', 'PageView');
      }
    }
  }
  firstVisit() {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        console.log('Analytics - FBQ - firstVisit');

        window.fbq('track', 'FirstVisit');
      }
    }
  }

  linkClick(link: string) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          const parameters = {
            link
          };
          console.log('Analytics FBQ - linkClick', parameters);

          window.fbq('track', 'LinkClick', parameters);
        } catch {}
      }
    }
  }
  pageScroll(percentage: string) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          const parameters = {
            percentage
          };
          console.log('Analytics FBQ - pageScroll', parameters);

          window.fbq('track', 'PageScroll', parameters);
        } catch {}
      }
    }
  }

  addToCart(product: any) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          const item = getModifiedSingleItem(product);

          const parameters = {
            items: [item]
          };
          console.log('Analytics - FBQ - AddToCart', parameters);

          window.fbq('track', 'AddToCart', parameters);
        } catch {}
      }
    }
  }

  viewCart(products: any) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          const items = getCartItems(products);
          if (items.length === 0) return;

          const parameters = {
            items
          };
          console.log('Analytics - FBQ - viewCart', parameters);

          window.fbq('track', 'ViewCart', parameters);
        } catch {}
      }
    }
  }

  purchase(cartState: any, paymentId: any) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          const items = getCartItems(cartState.items);
          if (items.length === 0) return;

          const parameters = {
            currency: cartState.unit,
            value: cartState.sum.val,
            // tax: 0,
            shipping: cartState.shipping.total,
            affiliation: 'Likenew',
            transaction_id: String(paymentId),
            coupon: cartState.coupon,
            items
          };
          console.log('Analytics - FBQ - Purchase', parameters);
          window.fbq('track', 'Purchase', parameters);
        } catch {}
      }
    }
  }

  // viewContent(product: any) {
  //   if (typeof window !== 'undefined') {
  //     if (window.fbq != null) {
  //       // Tylko jeden raz
  //       // jak dwa razy nie zawiera parametrów
  //       try {
  //         const currency = product.languages.code === 'pl' ? 'PLN' : 'EUR';
  //         const parameters = {
  //           content_ids: [`${product.databaseId}`],
  //           content_category: 'Biżuteria',
  //           content_name: product.name,
  //           content_type: 'product',
  //           contents: [{ id: product.databaseId, quantity: 1 }],
  //           // value: cleanPrice(product.price),
  //           currency
  //         };
  //         console.log('Analytics FBQ - ViewContent', parameters);
  //         // console.warn('Analytics - FBQ - ViewContent', parameters);

  //         window.fbq('track', 'ViewContent', parameters);
  //       } catch {}
  //     }
  //   }
  // }

  // addToCart(product: any, language: string) {
  //   if (typeof window !== 'undefined') {
  //     if (window.fbq != null) {
  //       // do sprawdzenia
  //       try {
  //         const currency = language === 'pl' ? 'PLN' : 'EUR';
  //         const parameters = {
  //           // value: cleanPrice(product.price),
  //           currency: currency,
  //           content_name: product.name,
  //           content_ids: [`${product.product_id}`],
  //           content_type: 'product',
  //           contents: [{ id: product.product_id, quantity: 1 }]
  //         };
  //         console.log('Analytics - FBQ - AddToCart', parameters);
  //         // console.warn('FBQ - AddToCart', parameters);

  //         window.fbq('track', 'AddToCart', parameters);
  //       } catch {}
  //     }
  //   }
  // }

  // todo TO CHECK
  // purchase(cartState: any) {
  //   if (typeof window !== 'undefined') {
  //     if (window.fbq != null) {
  //       try {
  //         const parameters = {
  //           value: cartState.sum.totalValue,
  //           currency: cartState.unit,
  //           num_items: cartState.sum.totalQuantity,
  //           content_ids: cartState.items.map(
  //             ({ databaseId }: any) => `${databaseId}`
  //           ),
  //           content_name: 'Succesfull purchase page',
  //           content_type: 'Product',
  //           contents: cartState.items.map(({ databaseId, quantity }: any) => ({
  //             id: databaseId,
  //             quantity
  //           }))
  //         };
  //         console.log('Analytics - FBQ - Purchase', parameters);
  //         // console.warn('FBQ - Purchase', parameters);

  //         window.fbq('track', 'Purchase', parameters);
  //       } catch {}
  //     }
  //   }
  // }
}

const FBPController = new PixelController();

export default FBPController;
