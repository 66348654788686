/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable */
import type { IAuthState, LoadAuthPayloadType } from '@contextTypes';
import Cookies from 'js-cookie';
import { IUserBilling, IUserInvoice } from 'types/context/Auth';

class AuthActions {
  loadAuth = (
    state: IAuthState,
    { payload }: { payload: LoadAuthPayloadType }
  ) => {
    console.log('CtxAuth loadAuth', payload);

    const { auth } = payload;

    return {
      ...state,
      ...auth
    };
  };

  logIn = (state: IAuthState, { payload }: { payload: any }) => {
    console.log('CtxAuth logIn', payload);
    const {
      billing_address_1,
      billing_address_2,
      billing_city,
      billing_company,
      billing_country,
      billing_email,
      billing_fist_name,
      billing_last_name,
      billing_phone,
      billing_postcode,
      city,
      email,
      country,
      firstName,
      id,
      lastName,
      nicename,
      phone,
      postcode,
      shipping_address_1,
      shipping_address_2,
      shipping_city,
      shipping_company,
      shipping_country,
      shipping_fist_name,
      shipping_last_name,
      shipping_phone,
      shipping_postcode,
      token,
      invoice
    } = payload;
    Cookies.set('token', token, { expires: 2 });
    return {
      ...state,
      loggedIn: true,
      id,
      email,
      nick: nicename,
      first_name: firstName,
      last_name: lastName,
      phone,
      country: country || 'PL',
      billing: {
        first_name: billing_fist_name,
        last_name: billing_last_name,
        companyName: billing_company,
        country: billing_country || 'PL',
        streetAdress: billing_address_1,
        apartment: billing_address_2,
        city: billing_city,
        zipNumber: billing_postcode,
        phoneNumber: billing_phone,
        emailAdress: billing_email
      },
      shipping: {
        first_name: shipping_fist_name,
        last_name: shipping_last_name,
        companyName: shipping_company,
        country: shipping_country || 'PL',
        streetAdress: shipping_address_1,
        apartment: shipping_address_2,
        city: shipping_city,
        zipNumber: shipping_postcode,
        phoneNumber: shipping_phone
      },
      city,
      postcode,
      wishlist: [],
      invoice: {
        address_1: invoice.address,
        address_2: invoice.address_2,
        city: invoice.city,
        company: invoice.company,
        nip: invoice.nip,
        phone: invoice.phone,
        zip_code: invoice.postcode
      }
    };
  };

  setShippingDetails = (
    state: IAuthState,
    { payload }: { payload: Record<string, string> }
  ) => {
    console.log('CtxAuth setShippingDetails', payload);
    return {
      ...state,
      shipping: {
        ...payload
      }
    };
  };

  setBillingDetails = (
    state: IAuthState,
    { payload }: { payload: Record<string, string> }
  ) => {
    console.log('CtxAuth setBillingDetails', payload);
    return {
      ...state,
      billing: {
        ...payload
      }
    };
  };

  setBilling = (state: IAuthState, { payload }: { payload: IUserBilling }) => {
    console.log('CtxAuth setBilling', payload);

    const billing = {
      ...state.billing,
      streetAdress: payload.address_1,
      apartment: payload.address_2,
      zipNumber: payload.zip_code,
      city: payload.city,
      country: payload.country,
      phoneNumber: payload.phoneNumber
    };
    return {
      ...state,
      billing
    };
  };

  setInvoice = (state: IAuthState, { payload }: { payload: IUserInvoice }) => {
    console.log('CtxAuth setInvoice', payload);
    return {
      ...state,
      invoice: payload
    };
  };

  setWishlist = (state: IAuthState, { payload }: { payload: number[] }) => {
    console.log('setting wishlist');
    return {
      ...state,
      wishlist: payload
    };
  };

  setBoughtProducts = (
    state: IAuthState,
    { payload }: { payload: number[] }
  ) => {
    console.log('setting setBoughtProducts');
    return {
      ...state,
      boughtProducts: payload
    };
  };

  setAccountDetails = (
    state: IAuthState,
    { payload }: { payload: Record<string, string> }
  ) => {
    console.log('CtxAuth setAccountDetails', payload);
    const {
      newEmail: email,
      newFirstName: first_name,
      newLastName: last_name,
      newPhone: phone
    } = payload;
    return {
      ...state,
      first_name,
      last_name,
      phone,
      email
    };
  };

  logOut = (state: IAuthState) => {
    Cookies.remove('token');
    return {
      ...state,
      loggedIn: false,
      id: 0,
      email: '',
      nick: '',
      first_name: '',
      last_name: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      country: '',
      phone: '',
      note: '',
      billing: {
        first_name: '',
        last_name: '',
        companyName: '',
        country: '',
        streetAdress: '',
        apartment: '',
        city: '',
        zipNumber: '',
        phoneNumber: '',
        emailAdress: ''
      },
      shipping: {
        first_name: '',
        last_name: '',
        companyName: '',
        country: '',
        streetAdress: '',
        apartment: '',
        city: '',
        zipNumber: '',
        phoneNumber: ''
      },
      invoice: {
        company: '',
        nip: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        phone: ''
      }
    };
  };

  setAuthBusy = (
    state: IAuthState,
    { payload }: { payload: Record<string, string> }
  ) => {
    const { isBusy } = payload;

    return {
      ...state,
      isBusy
    };
  };
}

const actions = new AuthActions();
export default actions;
