import { getModifiedSingleItem, getCartItems } from './utils';

declare global {
  interface Window {
    gtag: any;
  }
}

class GoogleAdsController {
  addToCart(product: any, quantity?: number) {
    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        const item = getModifiedSingleItem(product, quantity);
        const parameters = {
          send_to: 'AW-573239646/',
          currency: 'PLN',
          value: item.price,
          items: [item]
        };
        console.log('Analytics - GAdsC addToCart', parameters);
        window.gtag('event', 'add_to_cart', parameters);
      }
    }
  }

  viewContent(product: any) {
    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        const item = getModifiedSingleItem(product);
        const parameters = {
          send_to: 'AW-573239646/',
          currency: 'PLN',
          value: item.price,
          items: [item]
        };
        console.log('Analytics - GAdsC viewContent', parameters);
        window.gtag('event', 'view_item', parameters);
      }
    }
  }

  purchase(cartState: any, paymentId: any) {
    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        const items = getCartItems(cartState.items);
        if (items.length === 0) return;
        const parameters = {
          send_to: 'AW-573239646/',
          currency: 'PLN',
          value: cartState.sum.val,
          tax: 0,
          shipping: cartState.shipping.total,
          transaction_id: String(paymentId),
          coupon: cartState.coupon,
          items
        };
        console.log('Analytics - GAdsC purchase', parameters);
        window.gtag('event', 'purchase', parameters);
      }
    }
  }

  beginCheckout(cartState: any) {
    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        const items = getCartItems(cartState.items);
        if (items.length === 0) return;
        const parameters = {
          send_to: 'AW-573239646/',
          currency: 'PLN',
          value: cartState.sum.val,
          coupon: cartState.coupon,
          items
        };
        console.log('Analytics - GAdsC beginCheckout', parameters);
        window.gtag('event', 'begin_checkout', parameters);
      }
    }
  }

  addPaymentInfo(cartState: any, paymentType: any) {
    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        const items = getCartItems(cartState.items);
        if (items.length === 0) return;
        const parameters = {
          send_to: 'AW-573239646/',
          currency: 'PLN',
          value: cartState.sum.val,
          coupon: cartState.coupon,
          payment_type: paymentType,
          items
        };
        console.log('Analytics - GAdsC addPaymentInfo', parameters);
        window.gtag('event', 'add_payment_info', parameters);
      }
    }
  }
}

const GAdsController = new GoogleAdsController();

export default GAdsController;
