const ScaleSVG = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.75C10.4142 1.75 10.75 2.08579 10.75 2.5V3.53132L15.1233 4.2602C15.36 4.29965 15.5635 4.44997 15.6708 4.66459L18.1708 9.66459C18.2229 9.76873 18.25 9.88357 18.25 10C18.25 10.862 17.9076 11.6886 17.2981 12.2981C16.6886 12.9076 15.862 13.25 15 13.25C14.138 13.25 13.3114 12.9076 12.7019 12.2981C12.0924 11.6886 11.75 10.862 11.75 10C11.75 9.88357 11.7771 9.76873 11.8292 9.66459L13.875 5.57285L10.75 5.05201V15.9167H14.1667C14.5809 15.9167 14.9167 16.2525 14.9167 16.6667C14.9167 17.0809 14.5809 17.4167 14.1667 17.4167H10H5.83333C5.41912 17.4167 5.08333 17.0809 5.08333 16.6667C5.08333 16.2525 5.41912 15.9167 5.83333 15.9167H9.25V5.05201L6.12495 5.57285L8.17082 9.66459C8.22289 9.76873 8.25 9.88357 8.25 10C8.25 10.862 7.90759 11.6886 7.2981 12.2981C6.6886 12.9076 5.86195 13.25 5 13.25C4.13805 13.25 3.3114 12.9076 2.7019 12.2981C2.09241 11.6886 1.75 10.862 1.75 10C1.75 9.88357 1.77711 9.76873 1.82918 9.66459L4.32918 4.66459C4.43649 4.44997 4.64001 4.29965 4.8767 4.2602L9.25 3.53132V2.5C9.25 2.08579 9.58579 1.75 10 1.75ZM5 6.67705L6.74249 10.162C6.70487 10.5667 6.52721 10.9477 6.23744 11.2374C5.90925 11.5656 5.46413 11.75 5 11.75C4.53587 11.75 4.09075 11.5656 3.76256 11.2374C3.47279 10.9477 3.29513 10.5667 3.25751 10.162L5 6.67705ZM15 6.67705L13.2575 10.162C13.2951 10.5667 13.4728 10.9477 13.7626 11.2374C14.0908 11.5656 14.5359 11.75 15 11.75C15.4641 11.75 15.9092 11.5656 16.2374 11.2374C16.5272 10.9477 16.7049 10.5667 16.7425 10.162L15 6.67705Z"
      fill="#20272C"
    />
  </svg>
);

export default ScaleSVG;
