import * as React from 'react';

const LayoutGridSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.75a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h4A.25.25 0 0 0 9.25 9V5A.25.25 0 0 0 9 4.75H5ZM3.25 5c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v4A1.75 1.75 0 0 1 9 10.75H5A1.75 1.75 0 0 1 3.25 9V5ZM15 4.75a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h4a.25.25 0 0 0 .25-.25V5a.25.25 0 0 0-.25-.25h-4ZM13.25 5c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v4A1.75 1.75 0 0 1 19 10.75h-4A1.75 1.75 0 0 1 13.25 9V5ZM5 14.75a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h4a.25.25 0 0 0 .25-.25v-4a.25.25 0 0 0-.25-.25H5ZM3.25 15c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v4A1.75 1.75 0 0 1 9 20.75H5A1.75 1.75 0 0 1 3.25 19v-4ZM15 14.75a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h4a.25.25 0 0 0 .25-.25v-4a.25.25 0 0 0-.25-.25h-4Zm-1.75.25c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v4A1.75 1.75 0 0 1 19 20.75h-4A1.75 1.75 0 0 1 13.25 19v-4Z"
      fill="#21272C"
    />
  </svg>
);

export default LayoutGridSVG;
