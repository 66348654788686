import * as React from 'react';

const InstagramSVG = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66666 6.0835C7.23993 6.0835 6.08333 7.24009 6.08333 8.66683V15.3335C6.08333 16.7602 7.23993 17.9168 8.66666 17.9168H15.3333C16.7601 17.9168 17.9167 16.7602 17.9167 15.3335V8.66683C17.9167 7.24009 16.7601 6.0835 15.3333 6.0835H8.66666ZM4.58333 8.66683C4.58333 6.41167 6.4115 4.5835 8.66666 4.5835H15.3333C17.5885 4.5835 19.4167 6.41167 19.4167 8.66683V15.3335C19.4167 17.5887 17.5885 19.4168 15.3333 19.4168H8.66666C6.4115 19.4168 4.58333 17.5887 4.58333 15.3335V8.66683ZM12 10.2502C11.0335 10.2502 10.25 11.0337 10.25 12.0002C10.25 12.9667 11.0335 13.7502 12 13.7502C12.9665 13.7502 13.75 12.9667 13.75 12.0002C13.75 11.0337 12.9665 10.2502 12 10.2502ZM8.74999 12.0002C8.74999 10.2052 10.2051 8.75016 12 8.75016C13.7949 8.75016 15.25 10.2052 15.25 12.0002C15.25 13.7951 13.7949 15.2502 12 15.2502C10.2051 15.2502 8.74999 13.7951 8.74999 12.0002Z"
      fill="white"
    />
  </svg>
);

export default InstagramSVG;
