import * as React from 'react';

const ArrowRightSVG = (): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.47 5.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 1 1-1.06-1.06l4.72-4.72H5a.75.75 0 0 1 0-1.5h12.19l-4.72-4.72a.75.75 0 0 1 0-1.06Z"
      fill="#21272C"
    />
  </svg>
);

export default ArrowRightSVG;
