import * as React from 'react';

const DownloadSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.25a.75.75 0 0 1 .75.75v10.19l3.72-3.72a.75.75 0 1 1 1.06 1.06l-5 5a.75.75 0 0 1-1.06 0l-5-5a.75.75 0 1 1 1.06-1.06l3.72 3.72V4a.75.75 0 0 1 .75-.75Zm-8 13a.75.75 0 0 1 .75.75v2A1.25 1.25 0 0 0 6 20.25h12A1.25 1.25 0 0 0 19.25 19v-2a.75.75 0 0 1 1.5 0v2A2.75 2.75 0 0 1 18 21.75H6A2.75 2.75 0 0 1 3.25 19v-2a.75.75 0 0 1 .75-.75Z"
      fill="#21272C"
    />
  </svg>
);

export default DownloadSVG;
