import * as React from 'react';

const AdjustmentsSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 0 1 5.293 0H20a.75.75 0 0 1 0 1.5h-3.354a2.751 2.751 0 0 1-5.293 0H4a.75.75 0 0 1 0-1.5h7.354ZM8 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 0 1 5.292 0H20a.75.75 0 0 1 0 1.5h-9.354a2.751 2.751 0 0 1-5.292 0H4a.75.75 0 0 1 0-1.5h1.354ZM17 16.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 0 1 5.293 0H20a.75.75 0 0 1 0 1.5h-.354a2.751 2.751 0 0 1-5.293 0H4a.75.75 0 0 1 0-1.5h10.354Z"
      fill="#21272C"
    />
  </svg>
);

export default AdjustmentsSVG;
