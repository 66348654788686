const BuySVG = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75008 3.25C8.74362 3.25 8.63406 3.2548 8.49323 3.46938C8.35273 3.68348 8.25008 4.01867 8.25008 4.40476V5.08333H11.7501V4.40476C11.7501 4.01867 11.6474 3.68348 11.5069 3.46938C11.3661 3.2548 11.2565 3.25 11.2501 3.25H8.75008ZM13.2501 5.08333V4.40476C13.2501 3.78051 13.0893 3.14673 12.761 2.6464C12.433 2.14656 11.9067 1.75 11.2501 1.75H8.75008C8.09351 1.75 7.56718 2.14656 7.23916 2.6464C6.91082 3.14673 6.75008 3.78051 6.75008 4.40476V5.08333H5.46304C4.16842 5.08333 3.41675 6.35164 3.41675 7.5V15C3.41675 16.1484 4.16842 17.4167 5.46304 17.4167H14.5371C15.8317 17.4167 16.5834 16.1484 16.5834 15V7.5C16.5834 6.35164 15.8317 5.08333 14.5371 5.08333H13.2501ZM11.7501 6.58333V9.16667C11.7501 9.58088 12.0859 9.91667 12.5001 9.91667C12.9143 9.91667 13.2501 9.58088 13.2501 9.16667V6.58333H14.5371C14.6743 6.58333 15.0834 6.80741 15.0834 7.5V15C15.0834 15.6926 14.6743 15.9167 14.5371 15.9167H5.46304C5.32582 15.9167 4.91675 15.6926 4.91675 15V7.5C4.91675 6.80741 5.32582 6.58333 5.46304 6.58333H6.75008V9.16667C6.75008 9.58088 7.08587 9.91667 7.50008 9.91667C7.9143 9.91667 8.25008 9.58088 8.25008 9.16667V6.58333H11.7501Z"
        fill="#0074FF"
      />
    </svg>
  );
};

export default BuySVG;
