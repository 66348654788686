import * as React from 'react';

const CheckboxSVG = (): JSX.Element => (
  <svg
    width={10}
    height={8}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49828 0.439451C9.80787 0.71464 9.83575 1.18869 9.56056 1.49828L4.22723 7.49828C4.08491 7.6584 3.8809 7.75001 3.66667 7.75001C3.45245 7.75001 3.24844 7.6584 3.10612 7.49828L0.439451 4.49828C0.164263 4.18869 0.192148 3.71464 0.501735 3.43945C0.811322 3.16426 1.28538 3.19215 1.56056 3.50174L3.66667 5.87111L8.43945 0.501735C8.71464 0.192148 9.18869 0.164263 9.49828 0.439451Z"
      fill="white"
    />
  </svg>
);

export default CheckboxSVG;
