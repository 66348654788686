import * as React from 'react';

const LockSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75A3.25 3.25 0 0 0 8.75 7v3.25h6.5V7A3.25 3.25 0 0 0 12 3.75Zm4.75 6.5V7a4.75 4.75 0 1 0-9.5 0v3.25H7A2.75 2.75 0 0 0 4.25 13v6A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19v-6A2.75 2.75 0 0 0 17 10.25h-.25Zm-.75 1.5H7c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25h-1Zm-4 4a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.75.25a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Z"
      fill="#21272C"
    />
  </svg>
);

export default LockSVG;
