import * as React from 'react';

const BrandTwitterSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.219 4.157c-1.385-1.241-3.248-1.2-4.862-.596-1.875.702-3.132 2.435-3.107 4.444v.235c-2.691-.143-5.059-1.468-6.64-3.677a.75.75 0 0 0-1.264.07L4 5l-.654-.367-.002.003-.004.007-.011.022-.04.075a10.452 10.452 0 0 0-.522 1.23c-.282.801-.573 1.926-.585 3.185-.013 1.265.256 2.678 1.107 4.023.677 1.068 1.698 2.058 3.168 2.891-1.413.79-2.818 1.244-4.428 1.182a.75.75 0 0 0-.388 1.407c3.434 1.872 7.245 2.554 10.602 1.58 3.815-1.109 6.961-3.978 8.164-8.26l.001-.004c.345-1.251.521-2.543.524-3.84l.002-.004c.033-.076.086-.185.158-.329.079-.157.17-.331.269-.524.087-.169.181-.35.282-.549.402-.788.896-1.797 1.08-2.515a.748.748 0 0 0 .027-.203v-.001a.75.75 0 0 0-1.111-.657c-.678.328-1.325.505-2.056.705l-.364.1Zm1.454 1.152c-.268.08-.53.152-.786.221-.232.064-.457.125-.675.19a.75.75 0 0 1-.773-.223c-.834-.94-2.114-1.072-3.556-.532-1.318.494-2.151 1.682-2.133 3.025V9a.75.75 0 0 1-.73.75c-3.062.078-5.836-1.136-7.826-3.319a8.944 8.944 0 0 0-.012.035c-.247.702-.49 1.659-.5 2.704-.01 1.04.21 2.153.875 3.205.665 1.05 1.81 2.095 3.743 2.938a.75.75 0 0 1 .116 1.311c-1.16.772-2.351 1.417-3.656 1.79 2.438.853 4.897 1.012 7.065.383 3.344-.972 6.082-3.468 7.137-7.223a13.09 13.09 0 0 0 .47-3.548c0-.15.043-.277.056-.315v-.003c.021-.061.047-.124.072-.18a8.97 8.97 0 0 1 .192-.4c.079-.158.178-.349.283-.553.088-.17.181-.35.271-.528.126-.247.25-.496.367-.738Z"
      fill="#21272C"
    />
  </svg>
);

export default BrandTwitterSVG;
