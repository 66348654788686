/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IAuthState } from '@contextTypes';
import Cookies from 'js-cookie';

import { request } from '../../request';
import { IRegisterUserData, ILoginUserData, IUserData } from './types';

class WPAuthApi {
  validateToken = (token: string) => {
    const url = '/wp-json/jwt-auth/v1/token/validate';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  };

  deleteToken = () => {
    const token = localStorage.getItem('token');
    const url = '/wp-json/jwt-auth/v1/token/cancellation';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  };

  registerNewUser = (user: IRegisterUserData) => {
    const url = 'wp-json/wp/v2/users/register';
    const { email, password, name } = user;
    const config = {
      method: 'POST',
      data: {
        email,
        password,
        name
      }
    };
    return request(url, config);
  };

  loginUser = (user: ILoginUserData) => {
    const url = '/wp-json/jwt-auth/v1/token';
    const { email, password } = user;
    const config = {
      method: 'POST',
      data: {
        username: email,
        password
      }
    };
    return request(url, config);
  };

  registerWithFacebook(accessToken: string) {
    const url = 'wp-json/facebook/login';
    const config = {
      method: 'POST',
      data: {
        accessToken
      }
    };
    return request(url, config);
  }

  registerWithGoogle(access_token: string) {
    const url = 'wp-json/google/login';
    const config = {
      method: 'POST',
      data: {
        access_token
      }
    };
    return request(url, config);
  }

  getWishlist(userId: number) {
    const url = `wp-json/utils/wishlist/${userId}`;
    const config = {
      method: 'GET'
    };
    return request(url, config);
  }

  addToWishlist(userId: number, itemId: number) {
    const url = `wp-json/utils/add-to-wishlist`;
    const config = {
      method: 'POST',
      data: {
        user_id: userId,
        item_id: itemId
      }
    };
    return request(url, config);
  }

  removeFromWishlist(userId: number, itemId: number) {
    const url = `wp-json/utils/remove-from-wishlist`;
    const config = {
      method: 'DELETE',
      data: {
        user_id: userId,
        item_id: itemId
      }
    };
    return request(url, config);
  }

  removeAllFromWishlist(userId: number) {
    const url = `/wp-json/utils/clear-user-wishlist`;
    const token = Cookies.get('token');
    console.log(token);
    const config = {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id: userId
      }
    };
    return request(url, config);
  }

  requestResetPasswordLink({ email }: { email: string }) {
    const url = '/wp-json/wp/v2/users/remind_password';
    const config = {
      method: 'POST',
      data: {
        email
      }
    };
    return request(url, config);
  }

  changeUserPassword(password: string, token: string, email: string) {
    const url = '/wp-json/wp/v2/users/update_user_password';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      // withCredentials: true,
      data: {
        email,
        password
      }
    };
    return request(url, config);
  }

  updateUserData = (values: IUserData, id: number) => {
    const token = Cookies.get('token');
    const url = '/wp-json/wp/v2/users/update';

    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      // withCredentials: true,
      data: {
        id,
        ...values
      }
    };

    return request(url, config);
  };

  changeUserPasswordInsideAccount = (
    email: string,
    existing_password: string,
    password: string
  ) => {
    const token = Cookies.get('token');
    const url = '/wp-json/wp/v2/users/edit_user_password';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      // withCredentials: true,
      data: {
        email,
        existing_password,
        password
      }
    };
    return request(url, config);
  };

  deleteUser = (email: string, userID: string | number) => {
    const token = Cookies.get('token');
    // const url = '/wp-json/wp/v2/users/delete-user';
    const url = `/wp-json/wp/v2/users/${userID}`;

    const config = {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` }
      // data: {
      //   email
      // }
    };

    return request(url, config);
  };

  updateUserSecond = (user: any) => {
    const token = Cookies.get('token') || localStorage.getItem('token');
    const url = '/wp-json/check/user/update';
    const {
      user_id,
      is_exist,
      email,
      password,
      first_name,
      last_name,
      billing,
      invoice
    } = user;
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
        is_exist,
        email,
        first_name,
        last_name,
        password,
        billing,
        invoice
      }
    };
    return request(url, config);
  };

  getAnonymousCode = () => {
    const url = '/wp-json/check/access/anonymous';
    const config = {
      method: 'POST',
      data: {
        session_key:
          'R0)vTFIk)39q#M9MoVn;(-Pw?<k?43Ei52/j[_kZvM%U+0k^H~J3TP|}~e_^7BN'
      }
    };

    return request(url, config);
  };
}

const authApi = new WPAuthApi();
export default authApi;
