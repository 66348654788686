/* eslint-disable */
import {
  IAppActions,
  ICartActions,
  ICartState,
  ILangState
} from '@contextTypes';
import { XSVG } from '@symbols';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { InpostGeowidget } from 'react-inpost-geowidget';

import {
  AppCtx,
  CartCtx,
  LangCtx,
  useActions,
  useContextState
} from '@components/contexted';

import * as S from './styles';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'inpost-geowidget': InpostGeoProps;
    }
  }
}

interface InpostGeoProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  onpoint: string;
  token: string | undefined;
  language: string;
  config: string;
  langSelection: boolean;
}

const GeoWidget = (): JSX.Element => {
  const { GATSBY_INPOST_API_KEY } = process.env;
  const { language } = useContextState<ILangState>(LangCtx, ['language']);
  const { isModalOpen } = useContextState<ICartState>(CartCtx, 'isModalOpen');
  const { toggleModal } = useActions<ICartActions>(CartCtx, ['toggleModal']);
  const { toggleIsModalOpen } = useActions<IAppActions>(AppCtx, [
    'toggleIsModalOpen'
  ]);

  useEffect(() => {
    const item = localStorage.getItem('selected_locker');
    if (item) localStorage.removeItem('selected_locker');
  }, []);

  const handleClick = () => {
    toggleModal();
    toggleIsModalOpen();
  };

  const onPointCallback = (e) => {
    console.log(e);
    localStorage.setItem('selected_locker', JSON.stringify(e));
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <S.Overlay style={{ visibility: isModalOpen ? 'visible' : 'hidden' }}>
      <S.Dim onClick={() => handleClick()} />
      <S.Container>
        <S.Close onClick={() => handleClick()}>
          <XSVG />
        </S.Close>
        <S.MapWrapper>
          {/* <inpost-geowidget
            onpoint="afterPointSelected"
            token="eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjE5ODk5MjAzNDcsImlhdCI6MTY3NDU2MDM0NywianRpIjoiMmIxOGVjNzItYjFhZC00MDgxLTg1MTMtMGMwMGY1Nzg2ZjgzIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTp0LXJPV2g0S2lRb2pFVkFDZmRDMU9RWWJnSFgtQWFTakNDZk1JOWtMYnEwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZDc0YmZlZDMtOTBhMy00NGQ4LWFmZDUtNTA1YTE4NGY3YmEzIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6ImQ3NGJmZWQzLTkwYTMtNDRkOC1hZmQ1LTUwNWExODRmN2JhMyIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6IjRlOTY0ZmI3LWM1MzAtNDJjYS1hNWNhLWZkNGFkNWFiYjlmMyJ9.T5ywcg4RB3SPXI21x1HlxTF7Zk1SRNfOEztRMboUpv2SSl078dtBPfjvc8F_pHazPj8l6oyYF_IT8K2wzcOdvM6zaUcMyolpXGLmBU90ZwRssoFPBfDqvdzxfleDHhOFKFGIe5i-4j4WvOSUry3oufMoLdVaeNnKy3Dfl5SdAY4wYEybl43inhltcQkUhY1S9bddlGzQ4mSOkrW74x5_cNsYzjYXy8vyjO86LuZWE4hCjOacsTItYkUUG-LwVzABPjkWTSzhVaN-jKJNQZnwNMpjutFKAE5VTplwzEKe86iDM6DoPRq5oaE99Q21_it8HztmrKPgifVDCpUlx6QpXA"
            language={language}
            config="parcelcollect"
            langSelection={false}
          /> */}

          <InpostGeowidget
            token="eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjE5ODk5MjAzNDcsImlhdCI6MTY3NDU2MDM0NywianRpIjoiMmIxOGVjNzItYjFhZC00MDgxLTg1MTMtMGMwMGY1Nzg2ZjgzIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTp0LXJPV2g0S2lRb2pFVkFDZmRDMU9RWWJnSFgtQWFTakNDZk1JOWtMYnEwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZDc0YmZlZDMtOTBhMy00NGQ4LWFmZDUtNTA1YTE4NGY3YmEzIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6ImQ3NGJmZWQzLTkwYTMtNDRkOC1hZmQ1LTUwNWExODRmN2JhMyIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6IjRlOTY0ZmI3LWM1MzAtNDJjYS1hNWNhLWZkNGFkNWFiYjlmMyJ9.T5ywcg4RB3SPXI21x1HlxTF7Zk1SRNfOEztRMboUpv2SSl078dtBPfjvc8F_pHazPj8l6oyYF_IT8K2wzcOdvM6zaUcMyolpXGLmBU90ZwRssoFPBfDqvdzxfleDHhOFKFGIe5i-4j4WvOSUry3oufMoLdVaeNnKy3Dfl5SdAY4wYEybl43inhltcQkUhY1S9bddlGzQ4mSOkrW74x5_cNsYzjYXy8vyjO86LuZWE4hCjOacsTItYkUUG-LwVzABPjkWTSzhVaN-jKJNQZnwNMpjutFKAE5VTplwzEKe86iDM6DoPRq5oaE99Q21_it8HztmrKPgifVDCpUlx6QpXA"
            onPoint={onPointCallback}
          />
        </S.MapWrapper>
      </S.Container>
    </S.Overlay>
  );
};

export default GeoWidget;
