const XCircleSVG = (): JSX.Element => (
  <svg
    id="xCircle"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icons/X Circle">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM7.803 7.803C8.0959 7.51011 8.57077 7.51011 8.86366 7.803L10 8.93934L11.1363 7.803C11.4292 7.51011 11.9041 7.51011 12.197 7.803C12.4899 8.0959 12.4899 8.57077 12.197 8.86366L11.0607 10L12.197 11.1363C12.4899 11.4292 12.4899 11.9041 12.197 12.197C11.9041 12.4899 11.4292 12.4899 11.1363 12.197L10 11.0607L8.86366 12.197C8.57077 12.4899 8.0959 12.4899 7.803 12.197C7.51011 11.9041 7.51011 11.4292 7.803 11.1363L8.93934 10L7.803 8.86366C7.51011 8.57077 7.51011 8.0959 7.803 7.803Z"
        fill="#20272C"
      />
    </g>
  </svg>
);

export default XCircleSVG;
