import { ILangContext } from '@contextTypes';

import React from 'react';

import initialState from './state';

const LangCtx = React.createContext<ILangContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default LangCtx;
