import { CompareProviderProps, ICompareState } from '@contextTypes';

import React, { useLayoutEffect, useReducer, useEffect } from 'react';

import CompareCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const CompareProvider: React.FC<CompareProviderProps> = ({
  children,
  onLoad = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLayoutEffect(() => {
    onLoad(dispatch);
    const filteringData = localStorage.getItem('CompareCtx');
    if (filteringData) {
      const localStorageData = JSON.parse(filteringData) as ICompareState;
      dispatch({
        type: 'loadCompareState',
        payload: localStorageData
      });
    }
  }, []);

  useEffect(() => {
    console.log('CompareCtx state', state);
    localStorage.setItem('CompareCtx', JSON.stringify(state));
  }, [state]);

  return (
    <CompareCtx.Provider value={{ state: { ...state }, dispatch }}>
      {children}
    </CompareCtx.Provider>
  );
};

export { CompareProvider, CompareCtx };
