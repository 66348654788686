import * as React from 'react';

const TrashSVG = (): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.75a.25.25 0 0 0-.25.25v2.25h4.5V4a.25.25 0 0 0-.25-.25h-4Zm5.75 2.5V4A1.75 1.75 0 0 0 14 2.25h-4A1.75 1.75 0 0 0 8.25 4v2.25H5.009a.7.7 0 0 0-.016 0H4a.75.75 0 0 0 0 1.5h.31l.94 11.284A2.75 2.75 0 0 0 8 21.75h8a2.75 2.75 0 0 0 2.75-2.716l.94-11.284H20a.75.75 0 0 0 0-1.5h-4.25ZM15 7.75H5.815l.932 11.188c.002.02.003.041.003.062A1.25 1.25 0 0 0 8 20.25h8A1.25 1.25 0 0 0 17.25 19c0-.02 0-.042.003-.062l.932-11.188H15Zm-5 2.5a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75Zm4 0a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75Z"
      fill="#21272C"
    />
  </svg>
);

export default TrashSVG;
