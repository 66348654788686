import { IFilteringState } from '@contextTypes';

const initialState: IFilteringState = {
  sorting: 'default',
  prizeRange: {
    maxRange: 100000,
    minRange: 0
  },
  productsPages: {},
  productsFilters: {
    productsToShow: 30
  },
  blogFilters: {
    postsToShow: 6,
    searchedPhrase: ''
  },
  areFiltersVisible: true,
  productsDisplay: 'grid',
  filtersListBySlug: [],
  activePagesFilters: {}
};

export default initialState;
