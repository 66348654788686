import * as React from 'react';

const SearchSVG = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 3.25C5.52589 3.25 3.25 5.52589 3.25 8.33333C3.25 11.1408 5.52589 13.4167 8.33333 13.4167C11.1408 13.4167 13.4167 11.1408 13.4167 8.33333C13.4167 5.52589 11.1408 3.25 8.33333 3.25ZM1.75 8.33333C1.75 4.69746 4.69746 1.75 8.33333 1.75C11.9692 1.75 14.9167 4.69746 14.9167 8.33333C14.9167 9.88105 14.3826 11.304 13.4887 12.428L18.0303 16.9697C18.3232 17.2626 18.3232 17.7374 18.0303 18.0303C17.7374 18.3232 17.2626 18.3232 16.9697 18.0303L12.428 13.4887C11.304 14.3826 9.88105 14.9167 8.33333 14.9167C4.69746 14.9167 1.75 11.9692 1.75 8.33333Z"
      fill="white"
    />
  </svg>
);

export default SearchSVG;
