import * as React from 'react';

const LayoutListSVG = (): JSX.Element => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.75c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25H6ZM3.25 6A2.75 2.75 0 0 1 6 3.25h12A2.75 2.75 0 0 1 20.75 6v2A2.75 2.75 0 0 1 18 10.75H6A2.75 2.75 0 0 1 3.25 8V6ZM6 14.75c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-2c0-.69-.56-1.25-1.25-1.25H6ZM3.25 16A2.75 2.75 0 0 1 6 13.25h12A2.75 2.75 0 0 1 20.75 16v2A2.75 2.75 0 0 1 18 20.75H6A2.75 2.75 0 0 1 3.25 18v-2Z"
      fill="#21272C"
    />
  </svg>
);

export default LayoutListSVG;
