import { ICartContext } from '@contextTypes';

import React from 'react';

import initialState from './state';

const CartCtx = React.createContext<ICartContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default CartCtx;
