import { IAuthState } from '@contextTypes';

const initialState: IAuthState = {
  isBusy: false,
  loggedIn: false,
  id: 0,
  email: '',
  first_name: '',
  last_name: '',
  country: '',
  phone: '',
  billing: {
    first_name: '',
    last_name: '',
    companyName: '',
    country: '',
    streetAdress: '',
    apartment: '',
    city: '',
    zipNumber: '',
    phoneNumber: '',
    emailAdress: ''
  },
  shipping: {
    first_name: '',
    last_name: '',
    companyName: '',
    country: '',
    streetAdress: '',
    apartment: '',
    city: '',
    zipNumber: '',
    phoneNumber: '',
    emailAdress: ''
  },
  invoice: {
    company: '',
    nip: '',
    address_1: '',
    address_2: '',
    city: '',
    zip_code: '',
    phone: ''
  },
  wishlist: [],
  boughtProducts: []
};

export default initialState;
