const HamburgerSVG = (): JSX.Element => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66602 7C3.66602 6.44772 4.11373 6 4.66602 6H23.3327C23.885 6 24.3327 6.44772 24.3327 7C24.3327 7.55228 23.885 8 23.3327 8H4.66602C4.11373 8 3.66602 7.55228 3.66602 7ZM3.66602 14C3.66602 13.4477 4.11373 13 4.66602 13H23.3327C23.885 13 24.3327 13.4477 24.3327 14C24.3327 14.5523 23.885 15 23.3327 15H4.66602C4.11373 15 3.66602 14.5523 3.66602 14ZM3.66602 21C3.66602 20.4477 4.11373 20 4.66602 20H23.3327C23.885 20 24.3327 20.4477 24.3327 21C24.3327 21.5523 23.885 22 23.3327 22H4.66602C4.11373 22 3.66602 21.5523 3.66602 21Z"
      fill="#6C8193"
    />
  </svg>
);

export default HamburgerSVG;
