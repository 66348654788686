import {
  ICustomerState,
  ChangeValuePayloadType,
  LoadCustomerPayloadType
} from '@contextTypes';

class CustomerActions {
  changeValue = (
    state: ICustomerState,
    { payload }: { payload: ChangeValuePayloadType }
  ) => ({
    ...state,
    ...payload
  });

  loadCustomer = (
    state: ICustomerState,
    { payload }: { payload: LoadCustomerPayloadType }
  ) => {
    const { customer } = payload;
    return {
      ...state,
      ...customer
    };
  };
}

const actions = new CustomerActions();
export default actions;
