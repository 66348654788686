/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable @typescript-eslint/restrict-plus-operands */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ICartState } from '@contextTypes';
import Cookies from 'js-cookie';

import { request } from '../../request';
import { RegisterCustomer } from './types';

class WPEcommerceApi {
  registerAndGetToken = (customer: RegisterCustomer) => {
    const url = '/wp-json/wp/v2/users/register';
    const newUsername = customer.first_name + customer.last_name;
    const newPassword = Math.random().toString(36).substring(7);
    const nameHash = newUsername + Math.random().toString(36).substring(7);
    const emailHash = customer.email + Math.random().toString(36).substr(2, 5);
    const config = {
      method: 'POST',
      data: {
        name: nameHash,
        username: nameHash,
        email: emailHash,
        password: newPassword
      }
    };

    return request(url, config).then(() => {
      const secondUrl = '/wp-json/jwt-auth/v1/token';
      const secondConfig = {
        method: 'POST',
        data: {
          username: emailHash,
          password: newPassword
        }
      };
      return request(secondUrl, secondConfig);
    });
  };

  getDiscount = (items: any, coupon: string) => {
    const url = 'wp-json/v1/coupons/check-basket-discount';
    const config = {
      method: 'POST',
      data: {
        items,
        coupon
      }
    };
    return request(url, config);
  };

  postCreateOrder(
    user_id: any,
    customer: any,
    cart: any,
    shipping: any,
    coupon = {},
    token: string,
    paymentAndShippingMethods: {
      payment: string;
      shipping: string;
    },
    locker: any,
    inpostGabarit: string | null,
    time: string
  ) {
    console.log('onSubmitTest postCreateOrder start');

    const url = `/wp-json/wc/v3/orders`;
    console.log('onSubmitTest locker', locker);
    console.log('onSubmitTest inpostGabarit', inpostGabarit);
    const isInvoice = customer.diff_state;

    let packageSize = 'small';
    switch (inpostGabarit) {
      case 'A':
        packageSize = 'small';
        break;
      case 'B':
        packageSize = 'medium';
        break;
      case 'C':
        packageSize = 'large';
        break;
      default:
        packageSize = 'small';
    }

    const newshipping = {
      ...shipping,
      metaData: [
        {
          key: '_parcel_machine_id',
          value: locker || ''
        },
        {
          key: '_parcel_machine_desc',
          value: ''
        },
        {
          key: '_easypack_parcels',
          value: [
            {
              package_size: packageSize
            }
          ]
        },
        {
          key: '_easypack_send_method',
          value: 'parcel_machine'
        }
      ]
    };

    console.log('onSubmitTest newshipping', newshipping);

    const config = {
      method: 'POST',
      // withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        billing: isInvoice
          ? { ...customer, company: customer.diff_first_name }
          : customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [newshipping],
        meta_data: [
          {
            key: '_CUSTOMER_USER',
            value: user_id
          },
          {
            key: '_IS_INVOICE',
            value: isInvoice
          },
          {
            key: '_NIP',
            value: customer.diff_nip
          },
          {
            key: '_COMPANY_ADDRESS_1',
            value: customer.diff_address_1
          },
          {
            key: '_COMPANY_ADDRESS_2',
            value: customer.diff_address_2
          },
          {
            key: '_COMPANY_POSTCODE',
            value: customer.diff_postcode
          },
          {
            key: '_COMPANY_CITY',
            value: customer.diff_city
          },
          {
            key: '_COMPANY_COUNTRY',
            value: customer.diff_country
          },
          {
            key: '_payment_method_name',
            value: paymentAndShippingMethods.payment
          },
          {
            key: '_shipping_method',
            value: paymentAndShippingMethods.shipping
          },
          {
            key: '_paczkomat_id',
            value: locker || ''
          },
          {
            key: '_inpost_gabarit',
            value: inpostGabarit || ''
          },
          {
            key: '_parcel_machine_id',
            value: locker || ''
          },
          {
            key: '_parcel_machine_desc',
            value: ''
          },
          {
            key: '_easypack_parcels',
            value: [
              {
                package_size: packageSize
              }
            ]
          },
          {
            key: '_easypack_send_method',
            value: 'parcel_machine'
          },
          {
            key: '_click_button_time',
            value: time
          }
        ],
        ...coupon
      }
    };
    console.log('onSubmitTest quest(url, config)', url, config);
    return request(url, config);
  }

  changeOrder(id: string, data = { status_order: 'completed' }) {
    const token = Cookies.get('token');
    const url = `wp-json/wp/v2/orders/status/?id=${id}`;
    const config = {
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
      // withCredentials: true,
      data
    };
    return request(url, config);
  }

  searchByPaymentId(orderId: string) {
    const token = Cookies.get('token');
    const url = `wp-json/wp/v2/orders/searchById/?search=${orderId}`;
    const config = {
      method: 'GET',
      // withCredentials: true,

      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  }

  getCartItems(uuid: string) {
    const url = `wp-json/utils/cart/${uuid}`;
    const config = {
      method: 'GET'
    };
    return request(url, config);
  }

  saveCartItems(uuid: string, cartState: ICartState) {
    const url = `wp-json/utils/cart/save-cart`;
    const config = {
      method: 'POST',
      data: {
        uuid,
        cart: cartState.items
      }
    };
    console.warn(config);
    return request(url, config);
  }

  deleteSavedItems(uuid: string) {
    const url = `wp-json/utils/delete-cart`;
    const config = {
      method: 'DELETE',
      data: {
        uuid
      }
    };
    return request(url, config);
  }

  // reviews
  createProductReview(
    product_id: number,
    review: string,
    reviewer: string,
    reviewer_email: string,
    rating: number,
    user_id: number
  ) {
    // const token = Cookies.get('token');
    const token = Cookies.get('token');
    const url = `wp-json/utils/add-product-review`;
    const config = {
      method: 'POST',
      // withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        product_id,
        review,
        reviewer,
        reviewer_email,
        rating,
        user_id
      }
    };
    return request(url, config);
  }

  retrieveProductReviews(product_id: number) {
    const url = `wp-json/utils/get-product-reviews/${product_id}`;
    const config = {
      method: 'GET'
    };
    return request(url, config);
  }

  // search(phrase = '') {
  //   const urlProducts = `${this.route}/products?search=${phrase}`;
  //   const configProducts = {
  //     method: 'GET',
  //     withCredentials: true,
  //     auth: {
  //       username: KEY,
  //       password: SECRET
  //     }
  //   };
  //   const orders = () => request(urlProducts, configProducts);

  //   const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}`;
  //   const configPosts = {
  //     method: 'GET'
  //   };
  //   const posts = () => request(urlPosts, configPosts);

  //   const urlPages = `/wp-json/wp/v2/pages?search=${phrase}`;
  //   const configPages = {
  //     method: 'GET'
  //   };
  //   const pages = () => request(urlPages, configPages);

  //   return Promise.all([orders(), posts(), pages()]);
  // }

  getUserOrders(user_id: number) {
    const url = `/wp-json/utils/get-orders/${user_id}`;
    const config = {
      method: 'GET'
    };

    return request(url, config);
  }

  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods

    const { WOOCOMMERCE_AUTH_KEY, WOOCOMMERCE_AUTH_SECRET } = process.env;

    const url = '/wp-json/wc/v3/shipping/zones';
    const config = {
      method: 'GET',
      // withCredentials: true,
      auth: {
        username: WOOCOMMERCE_AUTH_KEY,
        password: WOOCOMMERCE_AUTH_SECRET
      }
    };

    return request(url, config).then((data: any[]) => {
      const [selected] = data.filter((el) => el.name === 'Remote');
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`;
      const config = {
        method: 'GET',
        // withCredentials: true,
        auth: {
          username: WOOCOMMERCE_AUTH_KEY,
          password: WOOCOMMERCE_AUTH_SECRET
        }
      };
      return request(url, config);
    });
  }
}

const ecommerceApi = new WPEcommerceApi();
export default ecommerceApi;
