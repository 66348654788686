const BrandLinkedInSVG = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00016 4.08325C4.4939 4.08325 4.0835 4.49366 4.0835 4.99992V14.9999C4.0835 15.5062 4.4939 15.9166 5.00016 15.9166H15.0002C15.5064 15.9166 15.9168 15.5062 15.9168 14.9999V4.99992C15.9168 4.49366 15.5064 4.08325 15.0002 4.08325H5.00016ZM2.5835 4.99992C2.5835 3.66523 3.66547 2.58325 5.00016 2.58325H15.0002C16.3349 2.58325 17.4168 3.66523 17.4168 4.99992V14.9999C17.4168 16.3346 16.3348 17.4166 15.0002 17.4166H5.00016C3.66547 17.4166 2.5835 16.3346 2.5835 14.9999V4.99992ZM6.66683 5.91659C7.08104 5.91659 7.41683 6.25237 7.41683 6.66659V6.67492C7.41683 7.08913 7.08104 7.42492 6.66683 7.42492C6.25262 7.42492 5.91683 7.08913 5.91683 6.67492V6.66659C5.91683 6.25237 6.25262 5.91659 6.66683 5.91659ZM6.66683 8.41659C7.08104 8.41659 7.41683 8.75237 7.41683 9.16659V13.3333C7.41683 13.7475 7.08104 14.0833 6.66683 14.0833C6.25262 14.0833 5.91683 13.7475 5.91683 13.3333V9.16659C5.91683 8.75237 6.25262 8.41659 6.66683 8.41659ZM9.25016 10.8333V13.3333C9.25016 13.7475 9.58595 14.0833 10.0002 14.0833C10.4144 14.0833 10.7502 13.7475 10.7502 13.3333V10.8333C10.7502 10.5901 10.8467 10.357 11.0186 10.1851C11.1906 10.0132 11.4237 9.91659 11.6668 9.91659C11.9099 9.91659 12.1431 10.0132 12.315 10.1851C12.4869 10.357 12.5835 10.5901 12.5835 10.8333V13.3333C12.5835 13.7475 12.9193 14.0833 13.3335 14.0833C13.7477 14.0833 14.0835 13.7475 14.0835 13.3333V10.8333C14.0835 10.1923 13.8289 9.57762 13.3757 9.12441C12.9225 8.6712 12.3078 8.41659 11.6668 8.41659C11.2822 8.41659 10.907 8.50828 10.5705 8.67955C10.433 8.51861 10.2285 8.41659 10.0002 8.41659C9.58595 8.41659 9.25016 8.75237 9.25016 9.16659V10.8333Z"
        fill="#21272C"
      />
    </svg>
  );
};

export default BrandLinkedInSVG;
