import { IAppState } from '@contextTypes';

class AppActions {
  loaded = (state: IAppState) => ({
    ...state,
    imBusy: false
  });

  loading = (state: IAppState) => ({
    ...state,
    imBusy: true
  });

  changeProductsStock = (
    state: IAppState,
    payload: { databaseId: number; stockQuantity: number }[]
  ) => ({
    ...state,
    productsStockQuantity: payload
  });

  changeIsModalOpen = (state: IAppState, payload: boolean) => ({
    ...state,
    isModalOpen: payload
  });

  toggleIsModalOpen = (state: IAppState) => ({
    ...state,
    isModalOpen: !state.isModalOpen
  });
}

const actions = new AppActions();
export default actions;
