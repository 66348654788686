/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @typescript-eslint/no-useless-constructor */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from 'axios';
import querystring from 'query-string';

const redirectUri = 'http://localhost:8000/';

const DEBUG = false;
const instance = axios.create({
  baseURL: 'https://rebuilder.localhost-group.com',
  timeout: 1000 * 60,
  headers: {
    'Access-Control-Allow-Origin': redirectUri,
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

const logger = (data, url) => {
  DEBUG &&
    console.log(
      url,
      `\n\t status: ${data.status}`,
      '\n\t payload: ',
      data.data
    );
  return data.data;
};

const request = (_url, _config = {}) => {
  DEBUG && console.log(_url, 'config', _config);
  const req = {
    url: _url,
    ..._config
  };
  if (!req.headers) {
    req.headers = {};
  }
  if (_config.multipart) {
    req.headers['Content-Type'] = 'multipart/form-data';
  }

  if (_config.query && Object.keys(_config.query).length !== 0) {
    req.url += `?${querystring.stringify(_config.query, {
      arrayFormat: 'bracket'
    })}`;
  }

  return instance
    .request(req)
    .then((data) => logger(data, _url))
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
};

class GITHUBAPI {
  constructor() {}

  exchangeCode(code) {
    const url = '/github/oauth';
    const data = new FormData();
    data.append('code', code);
    data.append('client_id', 'c9d3c978b0dccad06dd6');
    data.append('client_secret', '6e742245dc99fdde0f127f4cdacc2d2a5f3cb2c7');
    const config = {
      method: 'POST',
      multipart: true,
      data
    };
    return request(url, config);
  }

  addCollaborator(nick, product) {
    const url = '/github/oauth';
    const data = new FormData();
    data.append('nick', nick);
    data.append('products', product);
    const config = {
      method: 'PUT',
      multipart: true,
      data
    };
    return request(url, config);
  }
}

const GitHubApi = new GITHUBAPI();

export default GitHubApi;
