const LogoutSVG = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45783 3.29108C2.91104 2.83786 3.52573 2.58325 4.16667 2.58325H10C10.6409 2.58325 11.2556 2.83786 11.7088 3.29108C12.1621 3.74429 12.4167 4.35898 12.4167 4.99992V6.66658C12.4167 7.0808 12.0809 7.41658 11.6667 7.41658C11.2525 7.41658 10.9167 7.0808 10.9167 6.66658V4.99992C10.9167 4.7568 10.8201 4.52365 10.6482 4.35174C10.4763 4.17983 10.2431 4.08325 10 4.08325H4.16667C3.92355 4.08325 3.69039 4.17983 3.51849 4.35174C3.34658 4.52365 3.25 4.7568 3.25 4.99992V14.9999C3.25 15.243 3.34658 15.4762 3.51849 15.6481C3.69039 15.82 3.92355 15.9166 4.16667 15.9166H10C10.2431 15.9166 10.4763 15.82 10.6482 15.6481C10.8201 15.4762 10.9167 15.243 10.9167 14.9999V13.3333C10.9167 12.919 11.2525 12.5833 11.6667 12.5833C12.0809 12.5833 12.4167 12.919 12.4167 13.3333V14.9999C12.4167 15.6409 12.1621 16.2555 11.7088 16.7088C11.2556 17.162 10.6409 17.4166 10 17.4166H4.16667C3.52573 17.4166 2.91104 17.162 2.45783 16.7088C2.00461 16.2555 1.75 15.6409 1.75 14.9999V4.99992C1.75 4.35898 2.00461 3.74429 2.45783 3.29108ZM14.4697 6.96959C14.7626 6.67669 15.2374 6.67669 15.5303 6.96959L18.0303 9.46959C18.171 9.61024 18.25 9.80101 18.25 9.99992C18.25 10.1988 18.171 10.3896 18.0303 10.5302L15.5303 13.0302C15.2374 13.3231 14.7626 13.3231 14.4697 13.0302C14.1768 12.7374 14.1768 12.2625 14.4697 11.9696L15.6893 10.7499H5.83333C5.41912 10.7499 5.08333 10.4141 5.08333 9.99992C5.08333 9.5857 5.41912 9.24992 5.83333 9.24992H15.6893L14.4697 8.03025C14.1768 7.73736 14.1768 7.26248 14.4697 6.96959Z"
        fill="black"
      />
    </svg>
  );
};

export default LogoutSVG;
