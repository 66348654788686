import React from 'react';
import wrapRootElement from './src/AllContextsWrapper';
import MessengerChat from './src/components/layout/MessengerChat'

function onPreRouteUpdate({ location, prevLocation }) {}

function onRouteUpdate({ location, prevLocation }) {}

function onClientEntry() {}

export function onServiceWorkerUpdateReady(){window.location.reload(true)};


export const wrapPageElement = ({ element }) => {
  console.log("MessengerChatTest")
  return (
    <React.Fragment>
      {element}
      <MessengerChat />
    </React.Fragment>
  );
};

export { wrapRootElement, onRouteUpdate, onPreRouteUpdate, onClientEntry };
