const ViewMoreSVG = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 4.0835C9.95333 4.0835 9.91602 4.12081 9.91602 4.16683C9.91602 4.21285 9.95333 4.25016 9.99935 4.25016C10.0454 4.25016 10.0827 4.21285 10.0827 4.16683C10.0827 4.12081 10.0454 4.0835 9.99935 4.0835ZM8.41602 4.16683C8.41602 3.29238 9.1249 2.5835 9.99935 2.5835C10.8738 2.5835 11.5827 3.29238 11.5827 4.16683C11.5827 5.04128 10.8738 5.75016 9.99935 5.75016C9.1249 5.75016 8.41602 5.04128 8.41602 4.16683ZM9.99935 9.91683C9.95333 9.91683 9.91602 9.95414 9.91602 10.0002C9.91602 10.0462 9.95333 10.0835 9.99935 10.0835C10.0454 10.0835 10.0827 10.0462 10.0827 10.0002C10.0827 9.95414 10.0454 9.91683 9.99935 9.91683ZM8.41602 10.0002C8.41602 9.12571 9.1249 8.41683 9.99935 8.41683C10.8738 8.41683 11.5827 9.12571 11.5827 10.0002C11.5827 10.8746 10.8738 11.5835 9.99935 11.5835C9.1249 11.5835 8.41602 10.8746 8.41602 10.0002ZM9.99935 15.7502C9.95333 15.7502 9.91602 15.7875 9.91602 15.8335C9.91602 15.8795 9.95333 15.9168 9.99935 15.9168C10.0454 15.9168 10.0827 15.8795 10.0827 15.8335C10.0827 15.7875 10.0454 15.7502 9.99935 15.7502ZM8.41602 15.8335C8.41602 14.959 9.1249 14.2502 9.99935 14.2502C10.8738 14.2502 11.5827 14.959 11.5827 15.8335C11.5827 16.7079 10.8738 17.4168 9.99935 17.4168C9.1249 17.4168 8.41602 16.7079 8.41602 15.8335Z"
      fill="#6C8193"
    />
  </svg>
);

export default ViewMoreSVG;
