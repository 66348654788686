import * as React from 'react';

const HeartSVG = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01834 4.92643C6.54933 4.8764 6.07503 4.92402 5.62533 5.06629C5.17562 5.20857 4.76024 5.44242 4.40535 5.75311C4.05046 6.06381 3.76375 6.44462 3.56326 6.87156C3.36278 7.29851 3.25286 7.76234 3.24044 8.23385C3.22802 8.70537 3.31337 9.17434 3.4911 9.61125C3.66883 10.0482 3.9351 10.4435 4.27314 10.7725L4.27787 10.7771L4.27785 10.7771L10.0001 16.4444L15.7223 10.7771C15.7389 10.7607 15.756 10.7453 15.7736 10.7307C16.0858 10.4114 16.3325 10.0338 16.4995 9.61911C16.6752 9.18297 16.759 8.71526 16.7456 8.24524C16.7323 7.77523 16.622 7.31303 16.4218 6.88759C16.2216 6.46214 15.9357 6.08259 15.5821 5.77271C15.2285 5.46282 14.8147 5.22927 14.3666 5.08666C13.9185 4.94405 13.4459 4.89546 12.9782 4.94393C12.5105 4.9924 12.0578 5.13688 11.6485 5.36834C11.2392 5.5998 10.8821 5.91324 10.5995 6.28906C10.4574 6.47807 10.2345 6.58898 9.998 6.58832C9.76152 6.58767 9.5392 6.47551 9.39814 6.28572C9.11677 5.90715 8.76008 5.59094 8.3505 5.357C7.94093 5.12306 7.48735 4.97646 7.01834 4.92643ZM16.7232 11.897L10.5279 18.0329C10.2356 18.3224 9.76462 18.3224 9.47232 18.0329L3.22456 11.8451C2.73929 11.3722 2.35699 10.8041 2.10166 10.1765C1.8459 9.54774 1.72309 8.87287 1.74096 8.19436C1.75884 7.51584 1.917 6.84837 2.20551 6.23399C2.49401 5.6196 2.9066 5.0716 3.4173 4.62451C3.92799 4.17741 4.52573 3.8409 5.17287 3.63616C5.82001 3.43142 6.50253 3.3629 7.17745 3.43489C7.85237 3.50689 8.50508 3.71785 9.09446 4.0545C9.42286 4.24207 9.72763 4.46639 10.0032 4.72252C10.2786 4.46935 10.5828 4.24778 10.9102 4.06266C11.4991 3.72958 12.1505 3.52167 12.8236 3.45192C13.4966 3.38217 14.1768 3.4521 14.8215 3.65731C15.4663 3.86253 16.0618 4.19862 16.5707 4.64455C17.0796 5.09048 17.4909 5.63666 17.7791 6.24889C18.0672 6.86112 18.2258 7.52623 18.245 8.20259C18.2643 8.87895 18.1437 9.552 17.8909 10.1796C17.638 10.8072 17.2584 11.3759 16.7756 11.8501C16.7587 11.8667 16.7412 11.8823 16.7232 11.897Z"
      fill="white"
    />
  </svg>
);

export default HeartSVG;
