import { getColor } from '@styles/utils';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 999999;

  height: 100vh;
  width: 100vw;

  background-color: ${getColor('white')};
`;
