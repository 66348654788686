import { ILangState } from '@contextTypes';

class LangActions {
  setDefaultLang = (
    state: ILangState,
    { payload }: { payload: ILangState }
  ) => {
    const { language } = payload;
    return {
      ...state,
      language
    };
  };

  setLangPl = () => ({
    language: 'pl'
  });

  setLangEn = () => ({
    language: 'en'
  });
}

const actions = new LangActions();
export default actions;
