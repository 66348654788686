import * as React from 'react';

const UserSVG = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 3.25C8.57318 3.25 7.41658 4.4066 7.41658 5.83333C7.41658 7.26007 8.57318 8.41667 9.99992 8.41667C11.4267 8.41667 12.5833 7.26007 12.5833 5.83333C12.5833 4.4066 11.4267 3.25 9.99992 3.25ZM5.91658 5.83333C5.91658 3.57817 7.74476 1.75 9.99992 1.75C12.2551 1.75 14.0833 3.57817 14.0833 5.83333C14.0833 8.0885 12.2551 9.91667 9.99992 9.91667C7.74476 9.91667 5.91658 8.0885 5.91658 5.83333ZM7.7777 12.4167C6.73891 12.4167 5.77188 12.7278 5.085 13.243C4.40224 13.755 4.08325 14.3959 4.08325 15V17.5C4.08325 17.9142 3.74747 18.25 3.33325 18.25C2.91904 18.25 2.58325 17.9142 2.58325 17.5V15C2.58325 13.836 3.20076 12.7812 4.185 12.043C5.1651 11.3079 6.459 10.9167 7.7777 10.9167H12.2221C13.5408 10.9167 14.8347 11.3079 15.8148 12.043C16.7991 12.7812 17.4166 13.836 17.4166 15V17.5C17.4166 17.9142 17.0808 18.25 16.6666 18.25C16.2524 18.25 15.9166 17.9142 15.9166 17.5V15C15.9166 14.3959 15.5976 13.755 14.9148 13.243C14.228 12.7278 13.2609 12.4167 12.2221 12.4167H7.7777Z"
      fill="white"
    />
  </svg>
);

export default UserSVG;
