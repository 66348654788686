/* eslint-disable */
import { ICompareState } from '@contextTypes';
import { IProduct } from '@standardTypes';

class CompareActions {
  loadCompareState = (payload: ICompareState) => payload;

  addToCompare = (state: ICompareState, payload: IProduct) => ({
    ...state,
    items: [...state.items, payload]
  });

  removeFromCompare = (state: ICompareState, payload: IProduct) => ({
    ...state,
    items: state.items.filter((item) => item.databaseId !== payload.databaseId)
  });

  clearCompareArray = (state: ICompareState) => ({
    ...state,
    items: []
  });
}

const actions = new CompareActions();
export default actions;
