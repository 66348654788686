const ArrowIcon = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0303 4.46967C13.3232 4.76256 13.3232 5.23744 13.0303 5.53033L8.56066 10L13.0303 14.4697C13.3232 14.7626 13.3232 15.2374 13.0303 15.5303C12.7374 15.8232 12.2626 15.8232 11.9697 15.5303L6.96967 10.5303C6.67678 10.2374 6.67678 9.76256 6.96967 9.46967L11.9697 4.46967C12.2626 4.17678 12.7374 4.17678 13.0303 4.46967Z"
        fill="#6C8193"
      />
    </svg>
  );
};

export default ArrowIcon;
