import { IAppState } from '@contextTypes';
import { getColor } from '@styles/utils';

import { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { AppCtx, useContextState } from '@components/contexted';

import * as S from './styles';

interface IPageLoader {
  size: number;
}

const PageLoader = ({ size }: IPageLoader): JSX.Element => (
  <BeatLoader size={size} color={getColor('primary', '50')} loading />
);

export const WholePageLoader = (): JSX.Element => (
  <S.LoaderWrapper className="LoaderWrapper">
    <BeatLoader size={30} color={getColor('primary', '50')} loading />
  </S.LoaderWrapper>
);

// export const WholePageLoader = (): JSX.Element => {
//   const [loaded, setter] = useState(false);
//   const { imBusy } = useContextState<IAppState>(AppCtx, 'imBusy');

//   useEffect(() => {
//     if (imBusy === false) {
//       const timer = setTimeout(() => {
//         clearTimeout(timer);
//         setter(true);
//       }, 333);
//     }
//   }, [imBusy]);

//   return (
//     <>
//       {!loaded && (
//         <S.LoaderWrapper>
//           <BeatLoader size={30} color={getColor('primary', '50')} loading />
//         </S.LoaderWrapper>
//       )}
//     </>
//   );
// };
export default PageLoader;
