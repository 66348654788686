const MenuSVG = (): JSX.Element => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3999 4.75C5.26183 4.75 5.1499 4.86193 5.1499 5V9C5.1499 9.13807 5.26183 9.25 5.3999 9.25H9.3999C9.53797 9.25 9.6499 9.13807 9.6499 9V5C9.6499 4.86193 9.53797 4.75 9.3999 4.75H5.3999ZM3.6499 5C3.6499 4.0335 4.4334 3.25 5.3999 3.25H9.3999C10.3664 3.25 11.1499 4.0335 11.1499 5V9C11.1499 9.9665 10.3664 10.75 9.3999 10.75H5.3999C4.4334 10.75 3.6499 9.9665 3.6499 9V5ZM15.3999 4.75C15.2618 4.75 15.1499 4.86193 15.1499 5V9C15.1499 9.13807 15.2618 9.25 15.3999 9.25H19.3999C19.538 9.25 19.6499 9.13807 19.6499 9V5C19.6499 4.86193 19.538 4.75 19.3999 4.75H15.3999ZM13.6499 5C13.6499 4.0335 14.4334 3.25 15.3999 3.25H19.3999C20.3664 3.25 21.1499 4.0335 21.1499 5V9C21.1499 9.9665 20.3664 10.75 19.3999 10.75H15.3999C14.4334 10.75 13.6499 9.9665 13.6499 9V5ZM5.3999 14.75C5.26183 14.75 5.1499 14.8619 5.1499 15V19C5.1499 19.1381 5.26183 19.25 5.3999 19.25H9.3999C9.53797 19.25 9.6499 19.1381 9.6499 19V15C9.6499 14.8619 9.53797 14.75 9.3999 14.75H5.3999ZM3.6499 15C3.6499 14.0335 4.4334 13.25 5.3999 13.25H9.3999C10.3664 13.25 11.1499 14.0335 11.1499 15V19C11.1499 19.9665 10.3664 20.75 9.3999 20.75H5.3999C4.4334 20.75 3.6499 19.9665 3.6499 19V15ZM15.3999 14.75C15.2618 14.75 15.1499 14.8619 15.1499 15V19C15.1499 19.1381 15.2618 19.25 15.3999 19.25H19.3999C19.538 19.25 19.6499 19.1381 19.6499 19V15C19.6499 14.8619 19.538 14.75 19.3999 14.75H15.3999ZM13.6499 15C13.6499 14.0335 14.4334 13.25 15.3999 13.25H19.3999C20.3664 13.25 21.1499 14.0335 21.1499 15V19C21.1499 19.9665 20.3664 20.75 19.3999 20.75H15.3999C14.4334 20.75 13.6499 19.9665 13.6499 19V15Z"
      fill="#808080"
    />
  </svg>
);

export default MenuSVG;
