// import { cleanPrice } from '@utils';

export const resetDataLayer = (datalayer: any) =>
  datalayer.push({ ecommerce: undefined });

const generateCategories = (productCategories: any) => {
  return [...productCategories]
    .slice(0, 5)
    .reduce((acc: Record<string, string>, el: any, index: number) => {
      return {
        ...acc,
        [`item_category${index === 0 ? '' : index + 1}`]: el.name
      };
    }, {});
};

export const getModifiedSingleItem = (el: any, quantity?: number) => {
  return {
    item_id: String(el.product_id) || String(el.databaseId),
    item_name: el.name,
    price: el.price || el.regularPrice,
    item_brand: el.brand,
    item_category: el.productCategories,
    quantity: quantity ? quantity : el.quantity
  };
};

export const getModifiedItems = (
  products: any,
  item_list_name: string,
  item_list_id: string
) => {
  return products.map((el: any, productIndex: number) => {
    // console.log('GAC get ITEMS', el);
    return {
      ...getModifiedSingleItem(el, 1),
      item_list_name,
      item_list_id,
      index: productIndex
    };
  });
};

const generateCartItemsCategories = (productCategories: any) => {
  return [...productCategories].reduce(
    (acc: Record<string, string>, el: any, index: number) => {
      return {
        ...acc,
        [`item_category${index === 0 ? '' : index + 1}`]: el
      };
    },
    {}
  );
};

export const getCartItems = (products: any) => {
  return products.map((el: any) => {
    return getModifiedSingleItem(el);
  });
};
