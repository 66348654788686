/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable vars-on-top */

/* eslint-disable wrap-iife */

/* eslint-disable func-names */

/* eslint-disable one-var */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable no-var */
import React, { useEffect } from 'react';

const MessengerChat: React.FC = () => {
  console.log('MessengerChatTest load');

  useEffect(() => {
    console.log('MessengerChatTest uef');

    var chatbox = document.getElementById('fb-customer-chat');
    if (chatbox) {
      chatbox.setAttribute('page_id', '352642534754129');
      chatbox.setAttribute('attribution', 'biz_inbox');
    }

    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: 'v19.0'
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return (
    <>
      <div id="fb-root" />
      <div id="fb-customer-chat" className="fb-customerchat" />
    </>
  );
};
export default MessengerChat;
