import type { FilteringProviderProps, IFilteringState } from '@contextTypes';

import React, { useLayoutEffect, useReducer, useEffect } from 'react';

import FilteringCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const FilteringProvider: React.FC<FilteringProviderProps> = ({
  children,
  onLoad = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLayoutEffect(() => {
    onLoad(dispatch);
    const filteringData = localStorage.getItem('FilteringCtx');
    if (filteringData) {
      const localStorageData = JSON.parse(filteringData) as IFilteringState;
      dispatch({
        type: 'loadFiltering',
        payload: localStorageData
      });
    }
  }, []);

  useEffect(() => {
    console.log('FilteringCtx state', state);
    localStorage.setItem('FilteringCtx', JSON.stringify(state));
  }, [state]);

  return (
    <FilteringCtx.Provider value={{ state: { ...state }, dispatch }}>
      {children}
    </FilteringCtx.Provider>
  );
};

export { FilteringProvider, FilteringCtx };
