/* eslint-disable import/prefer-default-export */
import { IShippingRules } from '@contextTypes';

function getProperShippingsList(
  shippingsRules: IShippingRules = {},
  value: number
) {
  if (Object.keys(shippingsRules).length === 0) {
    return [];
  }

  const [rangeValue] = Object.keys(shippingsRules).filter(
    (el: any) => el !== 'always' || el !== 'free'
  );

  if (Number(rangeValue) < value) {
    return [...shippingsRules[rangeValue], ...shippingsRules.free];
  }
  return [...shippingsRules.always, ...shippingsRules.free];
}

export { getProperShippingsList };
