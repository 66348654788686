/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/indent */
import { IWindowState, WindowActionType } from '@contextTypes';

import actions from './actions';
import initialState from './state';

function windowReducer(
  state = initialState,
  action: WindowActionType
): IWindowState {
  switch (action.type) {
    case 'changeWindow':
      return actions.changeWindow(state, action);
    default:
      throw new Error('Wrong action type in window reducer');
  }
}

export default windowReducer;
