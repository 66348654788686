/* eslint-disable */
import { useContext, Context } from 'react';

import { AppProvider, AppCtx } from './App/Provider';
import { AuthProvider, AuthCtx } from './Auth/Provider';
import { CartProvider, CartCtx } from './Cart/Provider';
import { CompareProvider, CompareCtx } from './Compare/Provider';
import { CustomerProvider, CustomerCtx } from './Customer/Provider';
import { FilteringProvider, FilteringCtx } from './Filtering/Provider';
import { LangProvider, LangCtx } from './Lang/Provider';
import { WindowProvider, WindowCtx } from './Window/Provider';

interface ElasticObject {
  [key: string]: any;
}

const useActions = <T>(ctx: Context<any>, selected: string | string[]): T => {
  const { dispatch } = useContext<any>(ctx);

  if (Array.isArray(selected)) {
    return selected.reduce(
      (actions: ElasticObject, name: string) => ({
        ...actions,
        [name]: (payload: any) =>
          dispatch({
            type: name,
            payload
          })
      }),
      {}
    ) as any;
  }
  return {
    [selected]: (payload: any) =>
      dispatch({
        type: selected,
        payload
      })
  } as any;
};

const useContextState = <T>(
  ctx: Context<any>,
  selected: string | string[] = '*'
): T => {
  const { state } = useContext<any>(ctx);

  if (Array.isArray(selected)) {
    return selected.reduce(
      (states: ElasticObject, name: string) => ({
        ...states,
        [name]: state[name]
      }),
      {}
    ) as any;
  }
  return selected === '*'
    ? state
    : ({
        [selected]: state[selected]
      } as any);
};

export {
  useActions,
  useContextState,
  CartProvider,
  CartCtx,
  CustomerProvider,
  CustomerCtx,
  AppProvider,
  AppCtx,
  WindowProvider,
  WindowCtx,
  LangProvider,
  LangCtx,
  FilteringProvider,
  FilteringCtx,
  CompareProvider,
  CompareCtx,
  AuthProvider,
  AuthCtx
};
