import type { IFilteringContext } from '@contextTypes';

import { createContext } from 'react';

import initialState from './state';

const FilteringCtx = createContext<IFilteringContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default FilteringCtx;
