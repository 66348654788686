/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ICompareContext } from '@contextTypes';

import { createContext } from 'react';

import initialState from './state';

const CompareCtx = createContext<ICompareContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default CompareCtx;
