/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IAuthContext } from '@contextTypes';

import React from 'react';

import initialState from './state';

const AuthCtx = React.createContext<IAuthContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default AuthCtx;
