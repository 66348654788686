import Cookies from 'js-cookie';

import { request } from '../request';

class CreditAgricoleAPI {
  // route = '/wp-json/payu/v1';

  initPayment(orderId: string, token: string) {
    const url = `wp-json/caraty/v1/pay`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'POST',
      data: {
        order_id: orderId
      }
    };
    return request(url, config);
  }
}

const CreditAgricoleApi = new CreditAgricoleAPI();

export default CreditAgricoleApi;
