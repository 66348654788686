import Cookies from 'js-cookie';

import { request } from '../request';

class TpayAPI {
  route = '/wp-json/tpay/v1';

  initPayment(orderId: string, token: string, paymentGroupId: number) {
    const url = `${this.route}/payment`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'POST',
      data: {
        order_id: orderId,
        payment_group_id: paymentGroupId
      }
    };
    return request(url, config);
  }

  getTransactionStatus(orderId: string) {
    const token = Cookies.get('token');
    const url = `${this.route}/payment/${orderId}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'GET'
    };
    return request(url, config);
  }

  getTypePayment() {
    const url = `${this.route}/groups`;
    const config = {
      method: 'GET'
    };

    return request(url, config);
  }
}

const TpayApi = new TpayAPI();

export default TpayApi;
