import {
  getModifiedSingleItem,
  getCartItems,
  resetDataLayer,
  getModifiedItems
} from './utils';

declare global {
  interface Window {
    dataLayer: any;
  }
}

class GoogleAnalyticsController {
  // pageView() {
  //   if (typeof window !== 'undefined') {
  //     try {
  //       window.dataLayer = window.dataLayer || [];
  //       const parameters = {
  //         event: 'page_view'
  //       };

  //       window.dataLayer.push(parameters);
  //       console.log('Analytics - GAC pageView', window.dataLayer.pop());
  //     } catch {}
  //   }
  // }

  // firstVisit() {
  //   if (typeof window !== 'undefined') {
  //     try {
  //       window.dataLayer = window.dataLayer || [];
  //       const parameters = {
  //         event: 'first_visit'
  //       };

  //       window.dataLayer.push(parameters);
  //       console.log('Analytics - GAC firstVisit', window.dataLayer.pop());
  //     } catch {}
  //   }
  // }

  // linkClick(link: string) {
  //   if (typeof window !== 'undefined') {
  //     try {
  //       window.dataLayer = window.dataLayer || [];
  //       const parameters = {
  //         event: 'link_click',
  //         ecommerce: {
  //           link
  //         }
  //       };

  //       window.dataLayer.push(parameters);
  //       console.log('Analytics - GAC linkclick', window.dataLayer.pop());
  //     } catch {}
  //   }
  // }

  // pageScroll(percentage: string) {
  //   if (typeof window !== 'undefined') {
  //     try {
  //       window.dataLayer = window.dataLayer || [];
  //       const parameters = {
  //         event: 'page_scroll',
  //         ecommerce: {
  //           percentage
  //         }
  //       };

  //       window.dataLayer.push(parameters);
  //       console.log(
  //         'Analytics - GAC pageScroll',
  //         window.dataLayer.pop().ecommerce
  //       );
  //     } catch {}
  //   }
  // }

  viewItemList(products: any, item_list_name: string, item_list_id: string) {
    // Tylko jeden raz
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const items = getModifiedItems(products, item_list_name, item_list_id);
        if (items.length === 0) return;

        const parameters = {
          event: 'view_item_list',
          ecommerce: {
            items
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC viewItemList', window.dataLayer.pop());
      } catch {}
    }
  }

  viewItem(product: any) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];

        const item = getModifiedSingleItem(product);

        const parameters = {
          event: 'view_item',
          ecommerce: {
            items: [item]
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC viewItem', window.dataLayer.pop());
      } catch {}
    }
  }

  addToCart(product: any, quantity?: number) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const item = getModifiedSingleItem(product, quantity);

        const parameters = {
          event: 'add_to_cart',
          ecommerce: {
            items: [item]
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC addToCart', window.dataLayer.pop());
      } catch {}
    }
  }

  removeFromCart(product: any, quantity?: number) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const item = getModifiedSingleItem(product, quantity);

        const parameters = {
          event: 'remove_from_cart',
          ecommerce: {
            items: [item]
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC removeFromCart', window.dataLayer.pop());
      } catch {}
    }
  }

  addToWishlist(product: any) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const item = getModifiedSingleItem(product);

        const parameters = {
          event: 'add_to_wishlist',

          ecommerce: {
            currency: 'PLN',
            items: [item]
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC addToWishlist', window.dataLayer.pop());
      } catch {}
    }
  }

  viewCart(products: any) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const items = getCartItems(products);
        if (items.length === 0) return;

        const parameters = {
          event: 'view_cart',
          ecommerce: {
            items
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC viewCart', window.dataLayer.pop());
      } catch {}
    }
  }

  beginCheckout(products: any) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const items = getCartItems(products);
        if (items.length === 0) return;

        const parameters = {
          event: 'begin_checkout',
          ecommerce: {
            items
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC beginCheckout', window.dataLayer.pop());
      } catch {}
    }
  }

  purchase(cartState: any, paymentId: any) {
    if (typeof window !== 'undefined') {
      try {
        window.dataLayer = window.dataLayer || [];
        const items = getCartItems(cartState.items);
        if (items.length === 0) return;

        const parameters = {
          event: 'purchase',
          ecommerce: {
            currency: cartState.unit,
            value: cartState.sum.totalValue,
            tax: 0,
            shipping: cartState.shipping.total,
            affiliation: 'Krysiak',
            transaction_id: String(paymentId),
            coupon: cartState.coupon,
            items
          }
        };

        resetDataLayer(window.dataLayer);

        window.dataLayer.push(parameters);
        console.log('Analytics - GAC purchase', window.dataLayer.pop());
      } catch {}
    }
  }
}

const GAController = new GoogleAnalyticsController();

export default GAController;
