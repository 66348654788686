/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable no-param-reassign */

/* eslint-disable eqeqeq */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable prettier/prettier */

/* eslint-disable no-multi-spaces */

/* eslint-disable indent */

/* eslint-disable @typescript-eslint/indent */

/* eslint-disable operator-linebreak */
import {
  ICartState,
  ItemInterface,
  IShipping,
  IShippingRules
} from '@contextTypes';

import { GAController } from '../../../controller';
import { getProperShippingsList } from './utils';

class CartActions {
  initializeItems = (
    state: ICartState,
    { payload }: { payload: ItemInterface[] }
  ) => {
    const itemsArray = [...payload];

    const items = itemsArray.reduce((acc, singleItem) => {
      const { quantity, regularPrice, salePrice } = singleItem;

      const price = salePrice || regularPrice;
      const total = quantity * price;

      const processedItem = { ...singleItem, price, total };

      const productIndex = acc.findIndex(
        ({ product_id }) => singleItem.product_id === product_id
      );
      const isItemInCart = productIndex !== -1;

      if (isItemInCart) {
        return acc.map((existingProduct, index) => {
          if (index !== productIndex) return existingProduct;

          const additionalValue = quantity * price;

          return {
            ...existingProduct,
            quantity: quantity + existingProduct.quantity,
            total:
              existingProduct.price * existingProduct.quantity + additionalValue
          };
        });
      }
      return [...acc, processedItem];
    }, [] as ItemInterface[]);

    console.warn(items);

    const totalValue = items.reduce((acc, cur) => acc + cur.total, 0);
    const totalQuantity = items.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items,
      isBusy: false,
      sum: { totalValue, totalQuantity },
      shippings: getProperShippingsList(state.shippingRules, totalValue)
    };
  };

  addToCart = (state: ICartState, { payload }: { payload: ItemInterface }) => {
    const prevCart = JSON.parse(localStorage.getItem('CartCtxItems')!) || [];
    const newCart = [...prevCart, payload];

    console.log('newCart addToCart', newCart);
    localStorage.setItem('CartCtxItems', JSON.stringify(newCart));

    const item = { ...payload };
    const { quantity, regularPrice, salePrice } = item;
    GAController.addToCart(item);

    const price = salePrice || regularPrice;
    const total = quantity * price;

    const processedItem = { ...item, price, total };

    const productIndex = state.items.findIndex(
      ({ product_id }) => item.product_id === product_id
    );
    const isItemInCart = productIndex !== -1;

    let items: ItemInterface[] = [];
    const itemsCopy = [...state.items];

    if (isItemInCart) {
      items = itemsCopy.map((existingProduct, index) => {
        if (index !== productIndex) return existingProduct;

        const additionalValue = quantity * price;

        return {
          ...existingProduct,
          quantity: quantity + existingProduct.quantity,
          total:
            existingProduct.price * existingProduct.quantity + additionalValue
        };
      });
    } else {
      items = [...itemsCopy, processedItem];
    }

    const totalValue = items.reduce((acc, cur) => acc + cur.total, 0);
    const totalQuantity = items.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items,
      sum: { totalValue, totalQuantity },
      shippings: getProperShippingsList(state.shippingRules, totalValue)
    };
  };

  removeFromCart = (
    state: ICartState,
    { payload }: { payload: ItemInterface }
  ) => {
    const prevCart = JSON.parse(localStorage.getItem('CartCtxItems')!) || [];
    const newCart = prevCart.filter((el) => el.id !== payload.id);
    console.log('newCart removeFromCart', newCart);
    localStorage.setItem('CartCtxItems', JSON.stringify(newCart));

    const item = { ...payload };
    GAController.removeFromCart(item);

    const { product_id } = item;

    const newItems = [...state.items].filter(
      (el) => el.product_id !== product_id
    );

    const val = newItems.reduce((acc, cur) => acc + cur.total, 0);
    const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items: newItems,
      sum: { totalValue: val, totalQuantity: qty },
      shippings: getProperShippingsList(state.shippingRules, val)
    };
  };

  changeProductQty = (
    state: ICartState,
    { payload }: { payload: ItemInterface }
  ) => {
    const prevCart = JSON.parse(localStorage.getItem('CartCtxItems')!) || [];
    const newCart = prevCart.map((el) =>
      el.id === payload.id ? { ...el, quantity: payload.quantity } : el
    );
    console.log('newCart changeProductQty', newCart);

    localStorage.setItem('CartCtxItems', JSON.stringify(newCart));

    const item = { ...payload };
    const { product_id, quantity } = item;

    if (quantity <= 0) return { ...state };

    const newItems = [...state.items].map((el) => {
      if (el.product_id === product_id) {
        el.quantity = quantity;
        el.total = quantity * el.price;
      }
      return el;
    });

    const val = newItems.reduce((acc, cur) => acc + cur.total, 0);
    const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items: newItems,
      sum: { totalValue: val, totalQuantity: qty },
      shippings: getProperShippingsList(state.shippingRules, val)
    };
  };

  setShippings = (
    state: ICartState,
    { payload }: { payload: IShippingRules }
  ) => {
    console.log('setShippingsPayload: ', payload);
    const action = { ...payload };

    const val = state.items.reduce((acc, cur) => acc + cur.total, 0);

    return {
      ...state,
      shippingRules: action,
      shippings: getProperShippingsList(action, val)
    };
  };

  setShipping = (state: ICartState, { payload }: { payload: IShipping }) => {
    console.log('setShippingPayload: ', payload);
    return {
      ...state,
      shipping: { ...payload }
    };
  };

  setShippingLocker = (state: ICartState, { payload }: { payload: string }) => {
    console.log('setShippingLocker: ', payload);
    return {
      ...state,
      shipping: { ...state.shipping, locker: payload }
    };
  };

  setShippingTotal = (state: ICartState, { payload }: { payload: number }) => {
    console.log('setShippingTotal: ', payload);
    return {
      ...state,
      shipping: { ...state.shipping, total: payload }
    };
  };

  setCoupon = (state: ICartState, { payload }: { payload: string }) => ({
    ...state,
    coupon: payload
  });

  setPaymentMethodTitle = (
    state: ICartState,
    { payload }: { payload: string }
  ) => ({
    ...state,
    paymentMethodTitle: payload
  });

  setOrderFromApi = (
    state: ICartState,
    { payload }: { payload: Record<string, any> }
  ) => {
    console.log(`setOrderFromApiPayload: ${payload}`);

    return {
      ...state,
      isBusy: false,
      error: null,
      orderInApi: payload
    };
  };

  onOrderApiError = (state: ICartState, { payload }: { payload: any }) => {
    const action = { ...payload };

    return {
      ...state,
      isBusy: false,
      error: action
    };
  };

  loadCart = (
    state: ICartState,
    { payload: { cart } }: { payload: { cart: ICartState } }
  ) => {
    const { items } = cart;
    const val = items.reduce((acc, cur) => acc + cur.total, 0);
    const qty = items.reduce((acc, cur) => acc + cur.quantity, 0);
    return {
      ...state,
      items,
      sum: { totalValue: val, totalQuantity: qty }
    };
  };

  resetCart = (state: ICartState) => {
    console.log('newCart resetCart');
    localStorage.setItem('CartCtxItems', JSON.stringify([]));

    return {
      ...state,
      isBusy: false,
      items: [],
      shippings: getProperShippingsList(state.shippingRules, 0),
      orderInApi: {},
      // shipping: {},
      coupon: '',
      sum: { totalValue: 0, totalQuantity: 0 }
    };
  };

  changePaymentStatus = (
    state: ICartState,
    { payload }: { payload: Record<string, string> }
  ) => ({
    ...state,
    payment: payload
  });

  setCartBusy = (state: ICartState) => ({
    ...state,
    isBusy: true
  });

  toggleModal = (state: ICartState) => {
    return {
      ...state,
      isModalOpen: !state.isModalOpen
    };
  };

  closeModal = (state: ICartState) => {
    return {
      ...state,
      isModalOpen: false
    };
  };
}

const actions = new CartActions();
export default actions;
