const ListSVG = (): JSX.Element => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.59961 5.25C6.01382 5.25 6.34961 5.58579 6.34961 6V6.01C6.34961 6.42421 6.01382 6.76 5.59961 6.76C5.1854 6.76 4.84961 6.42421 4.84961 6.01V6C4.84961 5.58579 5.1854 5.25 5.59961 5.25ZM8.24966 6C8.24966 5.58579 8.58544 5.25 8.99966 5.25H20.5996C21.0138 5.25 21.3496 5.58579 21.3496 6C21.3496 6.41421 21.0138 6.75 20.5996 6.75H8.99966C8.58544 6.75 8.24966 6.41421 8.24966 6ZM5.59961 11.25C6.01382 11.25 6.34961 11.5858 6.34961 12V12.01C6.34961 12.4242 6.01382 12.76 5.59961 12.76C5.1854 12.76 4.84961 12.4242 4.84961 12.01V12C4.84961 11.5858 5.1854 11.25 5.59961 11.25ZM21.3496 11.9994C21.35 12.4136 21.0145 12.7496 20.6003 12.75L9.00031 12.76C8.58609 12.7604 8.25002 12.4249 8.24966 12.0106C8.2493 11.5964 8.5848 11.2604 8.99901 11.26L20.599 11.25C21.0132 11.2496 21.3493 11.5851 21.3496 11.9994ZM5.59961 17.25C6.01382 17.25 6.34961 17.5858 6.34961 18V18.01C6.34961 18.4242 6.01382 18.76 5.59961 18.76C5.1854 18.76 4.84961 18.4242 4.84961 18.01V18C4.84961 17.5858 5.1854 17.25 5.59961 17.25ZM8.24966 18C8.24966 17.5858 8.58544 17.25 8.99966 17.25H20.5996C21.0138 17.25 21.3496 17.5858 21.3496 18C21.3496 18.4142 21.0138 18.75 20.5996 18.75H8.99966C8.58544 18.75 8.24966 18.4142 8.24966 18Z"
      fill="#6C8193"
    />
  </svg>
);

export default ListSVG;
