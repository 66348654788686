import Cookies from 'js-cookie';

import { request } from '../request';

class PayUAPI {
  route = '/wp-json/payu/v1';

  initPayment(orderId: string, token: string) {
    console.log('onSubmitTest initPayment orderId', orderId);

    const url = `${this.route}/get_order/${orderId}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'GET'
    };
    return request(url, config);
  }

  getTransactionStatus(transactionId: string) {
    const token = Cookies.get('token');
    const url = `${this.route}/get_payment_status/${transactionId}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'GET'
    };
    return request(url, config);
  }
}

const PayUApi = new PayUAPI();

export default PayUApi;
